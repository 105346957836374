import * as types from '../types';
import initialState from './initialState';

export default function paymentsReducer(state = initialState.payments, action) {
  switch (action.type) {
    case types.FETCH_PAYMENTS_FULFILLED: {
      return { ...state, data: action.payload.data };
    }
    case types.CLEAR_PAYMENTS: {
      return {};
    }
    default:
      return state;
  }
}
