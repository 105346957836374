import * as types from '../types';
import initialState from './initialState';

function isActionPending(type) {
  return type.substring(type.length - 8) == '_PENDING';
}

function isActionCompleted(type) {
  return isActionFulfilled(type) || isActionRejected(type);
}

function isActionFulfilled(type) {
  return type.substring(type.length - 10) == '_FULFILLED';
}

function isActionRejected(type) {
  return type.substring(type.length - 9) == '_REJECTED';
}

function pendingAction(type) {
  return type.slice(0, -8).toLowerCase();
}

function actionLoading(type) {
  let obj = {};
  let key = pendingAction(type);
  obj[key] = true;
  return obj;
}

function completedAction(type) {
  if (isActionFulfilled(type)) {
    return type.slice(0, -10).toLowerCase();
  }
  else {
    return type.slice(0, -9).toLowerCase();
  }
}

export default function loadingReducer(state = initialState.loading, action) {
  if (isActionPending(action.type)) {
    return {
      ...state,
      inProgress: state.inProgress + 1,
      ...actionLoading(action.type)
    };
  }
  else if (isActionCompleted(action.type)) {
    let newState = { ...state };
    delete newState[completedAction(action.type)];
    newState.inProgress--;
    return newState;
  }
  return state;
}
