import * as types from '../types';
import initialState from './initialState';

export default function externalPaymentsReducer(state = initialState.externalPayments, action) {
  switch (action.type) {
    case types.FETCH_EXTERNAL_PAYMENTS_FULFILLED:
      return { data: action.payload.data };

    default:
      return state;
  }
}
