import * as types from '../../types';
import Confirmation from '../../../api/confirmation';

export { fetchPayment } from '../../resources/payment/actions';
export {
  fetchSplits,
  setSplitsQuery,
  setSplitsSortOrder,

} from '../../resources/split/actions';

export {
  sendEmailConfirmation
} from '../../actions';

export function unconfirmPayment(payment) {
  return function(dispatch) {
    dispatch({
      type: types.UNCONFIRM_PAYMENT,
      payload: Confirmation.delete(payment)
    });
  };
}

export function clearUnconfirmedPayment() {
  return {
    type: types.CLEAR_UNCONFIRMED_PAYMENT
  };
}

export function clearAccountRecurrenceErrors() {
  return {
    type: types.CLEAR_ACCOUNT_RECURRENCE_ERRORS
  };
}

export function hideWelcome() {
  return {
    type: types.HIDE_WELCOME
  };
}
