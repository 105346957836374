import * as types from '../types';
import initialState from './initialState';

export default function deletedBillRecurrenceReducer(state = initialState.deletedBillRecurrence, action) {
  switch (action.type) {
    case types.DELETE_BILL_RECURRENCE_FULFILLED: {
      return action.payload.data;
    }
    case types.CLEAR_DELETED_BILL_RECURRENCE: {
      return null;
    }
    default:
      return state;
  }
}
