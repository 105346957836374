import * as types from '../../types';
import moment from 'moment';

export const initialState = {
  loading: true,
  splitUpdating: false,
  currentStep: 'details',
  creditCardEnabled: false,
  minDate: null,
  maxDate: null,
  splitsValid: null,
  billFilters: [],
  docsAdded: false,
  submitting: false,
  activeTab: 'bills',
  errors: null,
  hasExpiredDiscount: false,
  isMixKeyModalOpen: false,
  isRemoveAllConfirmationModalOpen: false,
  isPlastiqConfirmationModalOpen: false,
  isCreditCardModalOpen: false,
  confirmationSplit: null,
  selectedSplit: null,
  billsQuery: null,
  splitsQuery: null,
  billsSortOrder: null,
  splitsSortOrder: null,
  accountsQuery: null,
  accountsSortOrder: null,
  accountsPage: 1,
  emailConfirmationSent: null,
  payToAccounts: {},
  showLoadingOverlay: false,
  splitsData: { portal_splits: [] }
};

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case types.SELECT_BILLS_PENDING: {
      return {
        ...state,
      };
    }
    case types.SELECT_BILLS_FULFILLED: {
      return {
        ...state,
      };
    }
    case types.FETCH_STAGED_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        currentStep: 'details'
      };
    }
    case types.FETCH_STAGED_PAYMENT_FULFILLED: {
      const data = payload.data;
      const payment = data.portal_payment;
      const minDate = data.min_date ? moment(data.min_date) : null;
      const maxDate = data.max_date ? moment(data.max_date) : null;
      return {
        ...state,
        payment,
        minDate,
        maxDate,
        loading: false,
        splitsData: payment.portal_splits_data,
        hasExpiredDiscount: payment.portal_splits_data.has_expired_discount,
      };
    }
    case types.FETCH_PAY_TO_ACCOUNTS_FULFILLED: {
      return {
        ...state,
        payToAccounts: payload.data
      };
    }
    case types.SET_VIEW_ONLY: {
      return {
        ...state,
        payment: {},
        splits: [],
        loading: false
      };
    }
    case types.SAVE_SPLIT_PENDING: {
      const split = meta.split;
      split.loading = true;
      split.amount = parseFloat(split.amount);
      let bill_ids;
      if (state.splitsData.bill_ids) {
        bill_ids = [
          split.bill_id,
          ...state.splitsData.bill_ids
        ];
      } else {
        bill_ids = [split.bill_id];
      }
      return {
        ...state,
        splitsData: {
          ...state.splitsData,
          bill_ids: bill_ids,
          portal_splits: [
            split,
            ...state.splitsData.portal_splits
          ]
        },
      };
    }
    case types.SAVE_SPLIT_FULFILLED: {
      return {
        ...state,
        hasExpiredDiscount: payload.data.has_expired_discount,
        splitsData: payload.data
      };
    }
    case types.FETCH_SPLITS_FULFILLED: {
      return {
        ...state,
        splitsData: payload.data
      };
    }
    case types.DELETE_ALL_SPLITS_PENDING: {
      return {
        ...state,
        hasExpiredDiscount: false,
        showLoadingOverlay: true,
        showRemoveAllLoadingMessage: true,
        splitsData: { portal_splits: [] }
      };
    }
    case types.DELETE_ALL_SPLITS_FULFILLED: {
      return {
        ...state,
        showLoadingOverlay: false,
        showRemoveAllLoadingMessage: false,
      };
    }
    case types.SAVE_SPLITS_PENDING: {
      return {
        ...state,
        showLoadingOverlay: true,
      };
    }
    case types.SAVE_SPLITS_FULFILLED: {
      return {
        ...state,
        hasExpiredDiscount: payload.data.has_expired_discount,
        splitsData: payload.data,
        showLoadingOverlay: false
      };
    }
    case types.SAVE_SPLITS_REJECTED: {
      return {
        ...state,
        errors: payload.response.data.errors
      };
    }
    case types.UPDATE_SPLIT_FULFILLED: {
      const splits = [...state.splitsData.portal_splits];
      return {
        ...state,
        splitUpdating: false,
        splitsData: {
          ...payload.data,
          portal_splits: splits
        }
      };
    }
    case types.UPDATE_SPLIT_PENDING: {
      return {
        ...state,
        splitUpdating: true,
      };
    }
    case types.SET_SPLIT: {
      const split = payload;
      const splits = [...state.splitsData.portal_splits];
      const splitIndex = getSplitIndex(state.splitsData.portal_splits, split);
      splits[splitIndex] = split;
      return {
        ...state,
        splitsData: {
          ...state.splitsData,
          portal_splits: splits
        }
      };
    }
    case types.DELETE_SPLIT_FULFILLED: {
      return {
        ...state,
        hasExpiredDiscount: payload.data.has_expired_discount,
        splitsData: payload.data
      };
    }
    case types.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: payload
      };
    }
    case types.SET_PAYMENT: {
      return {
        ...state,
        payment: {
          ...state.payment,
          ...payload
        }
      };
    }
    case types.CREATE_PORTAL_DOCUMENT_FULFILLED: {
      return {
        ...state,
        docsAdded: true,
        payment: {
          ...payload.data
        }
      };
    }
    case types.DELETE_PORTAL_DOCUMENT_FULFILLED: {
      return {
        ...state,
        docsAdded: true,
        payment: {
          ...payload.data
        }
      };
    }
    case types.CLEAR_DOCS_ADDED: {
      return {
        ...state,
        docsAdded: false
      };
    }
    case types.SAVE_PAYMENT_FULFILLED: {
      return {
        ...state,
        currentStep: 'confirm',
        payment: {
          ...state.payment,
          ...payload.data
        }
      };
    }
    case types.CONFIRM_PAYMENT_PENDING: {
      return {
        ...state,
        submitting: true
      };
    }
    case types.CONFIRM_PAYMENT_FULFILLED: {
      const payment = payload.data.portal_payment;
      return {
        ...state,
        currentStep: payment.plastiq_request_url ? 'plastiq' : 'done',
        payment: {
          ...state.payment,
          ...payment
        },
        submitting: false
      };
    }
    case types.CONFIRM_PAYMENT_REJECTED: {
      return {
        ...state,
        currentStep: 'details',
        errors: payload.response.data.errors,
        submitting: false
      };
    }
    case types.CLEAR_ERRORS: {
      return {
        ...state,
        errors: null
      };
    }
    case types.SET_CREDIT_CARD_ENABLED: {
      return {
        ...state,
        creditCardEnabled: payload
      };
    }
    case types.SET_SPLITS_VALID: {
      return {
        ...state,
        splitsValid: payload
      };
    }
    case types.ADD_BILL_FILTER: {
      return {
        ...state,
        billFilters: [
          ...state.billFilters,
          payload
        ]
      };
    }
    case types.REMOVE_BILL_FILTER: {
      const billFilters = state.billFilters.filter((f) => {
        return f.data.id != payload.data.id;
      });
      return {
        ...state,
        billFilters
      };
    }
    case types.CLEAR_BILL_FILTERS: {
      return {
        ...state,
        billFilters: []
      };
    }
    case types.SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload
      };
    }
    case types.TOGGLE_MIX_KEY_MODAL: {
      return {
        ...state,
        isMixKeyModalOpen: !state.isMixKeyModalOpen
      };
    }
    case types.TOGGLE_REMOVE_ALL_CONFIRMATION_MODAL: {
      return {
        ...state,
        isRemoveAllConfirmationModalOpen: !state.isRemoveAllConfirmationModalOpen
      };
    }
    case types.TOGGLE_PLASTIQ_CONFIRMATION_MODAL: {
      return {
        ...state,
        isPlastiqConfirmationModalOpen: !state.isPlastiqConfirmationModalOpen
      };
    }
    case types.TOGGLE_CREDIT_CARD_MODAL: {
      const newValue = (payload !== undefined) ? payload : !state.isCreditCardModalOpen;
      return {
        ...state,
        isCreditCardModalOpen: newValue
      };
    }
    case types.SET_CONFIRMATION_SPLIT: {
      return {
        ...state,
        confirmationSplit: payload
      };
    }
    case types.SET_SELECTED_SPLIT: {
      return {
        ...state,
        selectedSplit: payload
      };
    }
    case types.SET_BILLS_QUERY: {
      return {
        ...state,
        billsQuery: payload
      };
    }
    case types.SET_SPLITS_QUERY: {
      return {
        ...state,
        splitsQuery: payload
      };
    }
    case types.SET_BILLS_SORT_ORDER: {
      return {
        ...state,
        billsSortOrder: payload
      };
    }
    case types.SET_SPLITS_SORT_ORDER: {
      return {
        ...state,
        splitsSortOrder: payload
      };
    }
    case types.SET_ACCOUNTS_QUERY: {
      return {
        ...state,
        accountsQuery: payload
      };
    }
    case types.SET_ACCOUNTS_SORT_ORDER: {
      return {
        ...state,
        accountsSortOrder: payload
      };
    }
    case types.SET_ACCOUNTS_PAGE: {
      return {
        ...state,
        accountsPage: payload
      };
    }
    case types.SEND_EMAIL_CONFIRMATION_FULFILLED: {
      return {
        ...state,
        emailConfirmationSent: true
      };
    }
    case types.UPDATE_PAYMENT_PENDING: {
      return {
        ...state,
        showLoadingOverlay: true,
      };
    }
    case types.UPDATE_PAYMENT_FULFILLED: {
      return {
        ...state,
        showLoadingOverlay: false,
        hasExpiredDiscount: payload.data.portal_splits_data.has_expired_discount,
        payment: {
          ...state.payment,
          ...payload.data,
        },
        splitsData: payload.data.portal_splits_data
      };
    }
    case types.UPDATE_PAYMENT_REJECTED: {
      return {
        ...state,
        showLoadingOverlay: false,
        errors: payload.response.data.errors
      };
    }
    default:
      return state;
  }
};

function parseSplit(split) {
  split.amount = parseFloat(split.amount);
  split.persisted_amount = split.amount;
  return split;
}

function getSplits(payment) {
  if (payment && payment.portal_splits_data) {
    return payment.portal_splits_data.portal_splits.map((split) => {
      const amount = parseFloat(split.amount);
      return {
        ...split,
        amount,
        persisted_amount: amount
      };
    });
  }
}

function getSplitIndex(splits, split) {
  return splits.findIndex((s) => {
    return s.id === split.id;
  });
}
