import * as types from '../types';
import initialState from './initialState';

export default function payMethodAssignmentsReducer(state = initialState.payMethodAssignments, action) {
  switch (action.type) {
    case types.FETCH_PAY_METHOD_ASSIGNMENTS_FULFILLED: {
      return action.payload.data;
    }
    case types.CREATE_PAY_METHOD_ASSIGNMENT_FULFILLED: {
      const assignments = state || [];
      return [...assignments, action.payload.data];
    }
    case types.DELETE_PAY_METHOD_ASSIGNMENT_FULFILLED: {
      const deletedAssignment = action.payload.data.portal_pay_method_assignment;
      return state.filter((payMethod) => {
        return payMethod.id != deletedAssignment.pay_method_id;
      });
    }
    case types.CLEAR_PAY_METHOD_ASSIGNMENTS: {
      return null;
    }
    default:
      return state;
  }
}
