import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccountPaymentScreen from './screen';
import * as actionCreators from '../../state/screens/account_payment/actions';

function mapStateToProps(state) {
  return {
    loading: state.screens.accountPayment.loading,
    currentStep: state.screens.accountPayment.currentStep,
    account: state.screens.accountPayment.account,
    payment: state.screens.accountPayment.payment,
    errors: state.errors.payment,
    payMethodErrors: state.errors.pay_method,
    minDate: state.screens.accountPayment.minDate,
    maxDate: state.screens.accountPayment.maxDate,
    submitting: state.screens.accountPayment.submitting
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(AccountPaymentScreen);
