import * as types from '../types';
import initialState from './initialState';

export default function selectedBillsReducer(state = initialState.selectedBills, action) {
  switch (action.type) {
    case types.SELECT_BILLS_FULFILLED:
      return action.payload.data;
    case types.CLEAR_SELECTED_BILLS:
      return [];
    default:
      return state;
  }
}
