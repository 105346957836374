import * as types from '../types';
import initialState from './initialState';

export default function billRecurrencesReducer(state = initialState.billRecurrences, action) {
  switch (action.type) {
    case types.FETCH_BILL_RECURRENCES_FULFILLED:
      return { ...state, data: action.payload.data };

    case types.CLEAR_BILL_RECURRENCES:
      return { ...state, data: null };

    default:
      return state;
  }
}
