import * as types from '../types';
import initialState from './initialState';

export default function payMethodsReducer(state = initialState.payMethods, action) {
  switch (action.type) {
    case types.FETCH_PAY_METHODS_FULFILLED: {
      return action.payload.data;
    }
    case types.SAVE_PAY_METHOD_FULFILLED: {
      const data = action.payload.data;
      if (Array.isArray(data)) {
        return data;
      }
      else {
        return [...state, data];
      }
    }
    case types.DELETE_PAY_METHOD_FULFILLED: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
