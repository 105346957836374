import * as types from '../../types';
import ReceivableAccountAPI from '../../../api/receivableAccountAPI';

export {
  fetchStagedPayment,
  confirmPayment,
  updatePayment
} from '../../resources/payment/actions';

export {
  saveSplit,
  saveSplits,
  fetchSplits,
  deleteSplit,
  deleteAllSplits,
  updateSplit,
  setSplitsQuery,
  setSplitsSortOrder
} from '../../resources/split/actions';

export {
  setCurrentStep,
  setPayment,
  createPortalDocument
} from '../../actions';

export function fetchPayToAccounts(portalUser, params = {}) {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_PAY_TO_ACCOUNTS,
      payload: ReceivableAccountAPI.fetchAccounts(portalUser, {
        ...params,
        for_pay_to_account: true
      })
    });
  };
}

export function setSplit(split) {
  return {
    type: types.SET_SPLIT,
    payload: split
  };
}

export function setCreditCardEnabled(enabled) {
  return {
    type: types.SET_CREDIT_CARD_ENABLED,
    payload: enabled
  };
}

export function setSplitsValid(valid) {
  return {
    type: types.SET_SPLITS_VALID,
    payload: valid
  };
}

export function setViewOnly() {
  return {
    type: types.SET_VIEW_ONLY
  };
}

export function addBillFilter(filter) {
  return {
    type: types.ADD_BILL_FILTER,
    payload: filter
  };
}

export function removeBillFilter(filter) {
  return {
    type: types.REMOVE_BILL_FILTER,
    payload: filter
  };
}

export function clearBillFilters(filter) {
  return {
    type: types.CLEAR_BILL_FILTERS
  };
}

export function setActiveTab(tab) {
  return {
    type: types.SET_ACTIVE_TAB,
    payload: tab
  };
}

export function toggleMixKeyModal() {
  return {
    type: types.TOGGLE_MIX_KEY_MODAL
  };
}

export function togglePlastiqConfirmationModal() {
  return {
    type: types.TOGGLE_PLASTIQ_CONFIRMATION_MODAL
  };
}

export function toggleRemoveAllConfirmationModal() {
  return {
    type: types.TOGGLE_REMOVE_ALL_CONFIRMATION_MODAL
  };
}

export function toggleCreditCardModal(payload = undefined) {
  return {
    type: types.TOGGLE_CREDIT_CARD_MODAL,
    payload: payload
  };
}

export function setConfirmationSplit(split) {
  return {
    type: types.SET_CONFIRMATION_SPLIT,
    payload: split
  };
}

export function setSelectedSplit(split) {
  return {
    type: types.SET_SELECTED_SPLIT,
    payload: split
  };
}

export function setBillsQuery(billsQuery) {
  return {
    type: types.SET_BILLS_QUERY,
    payload: billsQuery
  };
}

export function setBillsSortOrder(billsSortOrder) {
  return {
    type: types.SET_BILLS_SORT_ORDER,
    payload: billsSortOrder
  };
}

export function setAccountsQuery(accountsQuery) {
  return {
    type: types.SET_ACCOUNTS_QUERY,
    payload: accountsQuery
  };
}

export function setAccountsSortOrder(accountsSortOrder) {
  return {
    type: types.SET_ACCOUNTS_SORT_ORDER,
    payload: accountsSortOrder
  };
}

export function setAccountsPage(accountsPage) {
  return {
    type: types.SET_ACCOUNTS_PAGE,
    payload: accountsPage
  };
}
