import * as types from './types';
import Subscription from '../../../api/subscription';

export function subscribe(noticeRule) {
  return {
    type: types.SUBSCRIBE,
    payload: Subscription.create(noticeRule)
  };
}

export function unsubscribe(subscription) {
  return {
    type: types.UNSUBSCRIBE,
    payload: Subscription.destroy(subscription)
  };
}