import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { hashHistory } from 'react-router';
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal';
import FormErrors from 'shared/components/FormErrors';
import PaymentForm from '../../components/common/PaymentForm';
import PaymentDetails from '../../components/common/PaymentDetails';
import PaymentAPI from '../../api/paymentAPI';
import BillDocumentAPI from '../../api/billDocumentAPI';
import BillDocumentsModal from '../../components/common/BillDocumentsModal';
import EmailConfirmationModal from '../../components/common/EmailConfirmationModal';
import TabSet from '../../components/common/TabSet';
import Tab from '../../components/common/Tab';
import Icon from 'shared/components/Icon';
import BillAPI from '../../api/billAPI';
import BillsTable from '../../components/common/BillsTable';
import AccountsTable from '../../components/common/AccountsTable';
import SplitsTable from '../../components/common/SplitsTable';
import Spinner from '../../components/common/Spinner';
import ConfirmModal from '../../components/common/ConfirmModal';
import PaymentConfirmationMessage from '../../components/common/PaymentConfirmationMessage';
import DocumentUploader from '../../components/common/DocumentUploader';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import Checkbox from '../../components/common/Checkbox';
import NoticePopover from '../../components/common/NoticePopover';

class BillPaymentScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDocsModalOpen: false,
      docs: [],
      isConfirmationModalOpen: false,
      docUploadContext: null,
      isEmailConfirmationModalOpen: false,
      isRelatedBillsModalOpen: false,
      billForRelation: null,
      paymentAuthorizationChecked: false,
    };

    this.amount = this.amount.bind(this);
    this.areBillsValid = this.areBillsValid.bind(this);
    this.confirmPayment = this.confirmPayment.bind(this);
    this.billsTabSelected = this.billsTabSelected.bind(this);
    this.accountsTabSelected = this.accountsTabSelected.bind(this);
    this.pageBills = this.pageBills.bind(this);
    this.pageSplits = this.pageSplits.bind(this);
    this.sortBills = this.sortBills.bind(this);
    this.sortSplits = this.sortSplits.bind(this);
    this.filterBills = this.filterBills.bind(this);
    this.filterSplits = this.filterSplits.bind(this);
    this.selectAllBills = this.selectAllBills.bind(this);
    this.selectAllDueBills = this.selectAllDueBills.bind(this);
    this.exportBillsCsv = this.exportBillsCsv.bind(this);
    this.exportBillsPdf = this.exportBillsPdf.bind(this);
    this.billRowClicked = this.billRowClicked.bind(this);
    this.isBillSelected = this.isBillSelected.bind(this);
    this.isDiscountExpired = this.isDiscountExpired.bind(this);
    this.disableBillRowClick = this.disableBillRowClick.bind(this);
    this.accountRowClicked = this.accountRowClicked.bind(this);
    this.isAccountSelected = this.isAccountSelected.bind(this);
    this.filterAccounts = this.filterAccounts.bind(this);
    this.pageAccounts = this.pageAccounts.bind(this);
    this.sortAccounts = this.sortAccounts.bind(this);
    this.isSplitSelected = this.isSplitSelected.bind(this);
    this.toggleSelectedSplit = this.toggleSelectedSplit.bind(this);
    this.handleSplitAmountChange = this.handleSplitAmountChange.bind(this);
    this.handleSplitAmountReasonChange = this.handleSplitAmountReasonChange.bind(this);
    this.handleSplitAccountCommentChange = this.handleSplitAccountCommentChange.bind(this);
    this.handleSplitChange = this.handleSplitChange.bind(this);
    this.confirmNonCreditCardPayment = this.confirmNonCreditCardPayment.bind(this);
    this.downloadBillDocument = this.downloadBillDocument.bind(this);
    this.editDetails = this.editDetails.bind(this);
    this.submitPayment = this.submitPayment.bind(this);
    this.printPayment = this.printPayment.bind(this);
    this.removeAllSplits = this.removeAllSplits.bind(this);
    this.fetchBillsFilterAccounts = this.fetchBillsFilterAccounts.bind(this);
    this.clearBillsFilterAccounts = this.clearBillsFilterAccounts.bind(this);
    this.addBillsAccountFilter = this.addBillsAccountFilter.bind(this);
    this.removeBillsAccountFilter = this.removeBillsAccountFilter.bind(this);
    this.closeDocUploader = this.closeDocUploader.bind(this);
    this.closeRelatedBillsModal = this.closeRelatedBillsModal.bind(this);
    this.addDocs = this.addDocs.bind(this);
    this.uploadDocs = this.uploadDocs.bind(this);
    this.toggleDocsUploadModal = this.toggleDocsUploadModal.bind(this);
    this.toggleRelatedBillsModal = this.toggleRelatedBillsModal.bind(this);
    this.openAccountDocUploader = this.openAccountDocUploader.bind(this);
    this.openSplitDocUploader = this.openSplitDocUploader.bind(this);
    this.toggleEmailConfirmationModal = this.toggleEmailConfirmationModal.bind(this);
    this.createBillsFromSplits = this.createBillsFromSplits.bind(this);
    this.checkForCcRestrictions = this.checkForCcRestrictions.bind(this);
    this.rejectNonCreditCardPayment = this.rejectNonCreditCardPayment.bind(this);
    this.hasCcPayMethod = this.hasCcPayMethod.bind(this);
    this.onPaymentAuthChange = this.onPaymentAuthChange.bind(this);
    this.plastiqConfirmation = this.plastiqConfirmation.bind(this);
  }

  componentDidMount() {
    const defaultSortField = this.props.config.default_bill_sort_field + " ASC";
    this.props.clearErrors();
    this.props.clearBillFilters();
    this.props.fetchBills({
      sort_order: defaultSortField
    });
    this.fetchPayToAccounts();
    this.props.fetchPayMethods();
    this.fetchStagedPayment();
    this.props.setBillsSortOrder(defaultSortField);
  }

  componentDidUpdate(prevProps) {
    this.handleDateChange(prevProps);
    this.handleSplitsChange(prevProps);
    this.handleBillFiltersChange(prevProps);
    this.handleAccountsQueryChange(prevProps);
    this.handleAccountsPageChange(prevProps);
  }

  handleAccountsQueryChange(prevProps) {
    if (prevProps.accountsQuery != this.props.accountsQuery)
      this.filterAccounts(this.props.accountsQuery);
  }

  handleAccountsPageChange(prevProps) {
    if (prevProps.accountsPage != this.props.accountsPage)
      this.pageAccounts(this.props.accountsPage);
  }

  handleSelectedBills(nextProps) {
    if (nextProps.selectedBills.length) {
      this.addBills(nextProps.selectedBills);
      this.props.clearSelectedBills();
    }
  }

  handleBillFiltersChange(prevProps) {
    if (prevProps.billFilters.toString() != this.props.billFilters.toString()) {
      this.filterBills(prevProps.billsQuery, this.props.billsQuery);
    }
  }

  handleDateChange(prevProps) {
    if (!prevProps.payment) return;

    if (prevProps.payment.scheduled_date != this.props.payment.scheduled_date) {
      const { portal_splits } = this.props.splitsData;
      let splitsValid = true;

      portal_splits.map((split) => {
        if (!this.isSplitValid(split)) splitsValid = false;
      });

      this.props.setSplitsValid(splitsValid);
    }
  }

  handleSplitsChange(prevProps) {
    if (prevProps.splitsData.portal_splits != this.props.splitsData.portal_splits) {
      const { portal_splits } = this.props.splitsData;
      const creditCardEnabled = this.isCreditCardEnabled(portal_splits);
      const amountProp = this.props.config.fees_enabled ? 'subtotal' : 'amount';
      let splitsValid = true;
      let splitsTotal = this.props.splitsData.portal_splits_amount;

      portal_splits.map((split) => {
        if (!this.isSplitValid(split)) splitsValid = false;
      });

      if (this.props.splitsData.portal_splits && portal_splits.length > 1 && this.hasCcPayMethod()) {
        this.checkForCcRestrictions(portal_splits);
      }

      this.props.setPayment({ [amountProp]: splitsTotal });
      this.props.setCreditCardEnabled(creditCardEnabled);
      this.props.setSplitsValid(splitsValid);
    }
  }

  handleSplitAmountChange(split, amount) {
    this.props.setSplit({
      ...split,
      amount
    });
    this.props.updateSplit(this.props.payment, split, { amount: amount });
  }

  handleSplitAmountReasonChange(split, amount_reason) {
    this.props.setSplit({
      ...split,
      amount_reason
    });
  }

  handleSplitAccountCommentChange(split, pay_to_account_comment) {
    this.props.setSplit({
      ...split,
      pay_to_account_comment
    });
  }

  handleSplitChange(split) {
    this.props.updateSplit(this.props.payment, split);
  }

  fetchStagedPayment() {
    if (this.props.abilities.manage_portal_payment) {
      this.props.fetchStagedPayment();
    }
    else {
      this.props.setViewOnly();
    }
  }

  goToDashboard() {
    hashHistory.push('/payer');
  }

  paymentForm() {
    return (
      <PaymentForm
        showBalance
        verticalLayout
        disableContinue
        splitUpdating={this.props.splitUpdating}
        areBillsInDateRage={this.areBillsValid}
        payment={this.props.payment}
        payMethods={this.props.payMethods}
        onChange={this.props.setPayment}
        onSubmitPayMethod={this.props.savePayMethod}
        onPayMethodError={this.props.setPayMethodErrors}
        payMethodErrors={this.props.payMethodErrors}
        savedPayMethod={this.props.savedPayMethod}
        docsAdded={this.props.docsAdded}
        clearPayMethodErrors={this.props.clearSavePayMethodErrors}
        clearSavedPayMethod={this.props.clearSavedPayMethod}
        clearDocsAdded={this.props.clearDocsAdded}
        deletePortalDocument={this.props.deletePortalDocument}
        creditCardEnabled={this.props.creditCardEnabled}
        plastiqEnabled={this.props.config.pay_method_config.plastiq_enabled}
        splitsValid={this.props.splitsValid}
        onContinue={this.confirmPayment}
        updatePayment={this.props.updatePayment}
        clearErrors={this.props.clearErrors}
        hasExpiredDiscount={this.props.hasExpiredDiscount}
        i18n={this.props.i18n}
        config={this.props.config}
        content={this.props.content}
        abilities={this.props.abilities}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        createStripeIntent={this.props.createStripeIntent}
        stripeIntent={this.props.config.stripeIntent} />
    );
  }

  hasCcPayMethod() {
    let cCPayMethodExists = false;
    this.props.payMethods.map((payMethod) => {
      if (payMethod.is_credit_card == true) cCPayMethodExists = true;
    });

    return cCPayMethodExists;
  }

  areBillsValid() {
    let billsValid = true;

    this.props.splitsData.portal_splits.map((split) => {
      if (!this.isBillValid(split)) billsValid = false;
    });

    return billsValid;
  }

  isBillValid(split) {
    let scheduledDate = moment(this.props.payment.scheduled_date).format('YYYY-MM-DD');
    let isValid = true;

    if (split.bill) {
      let dueDate = moment(split.bill.due_date).format('YYYY-MM-DD');
      let today = moment(this.props.config.current_date_for_biller);

      if (moment(scheduledDate).isAfter(dueDate) && !(moment(today).isAfter(dueDate))) {
        isValid = false;
      }
    }

    return isValid;
  }

  isSplitValid(split) {
    let isValid = split.amount;
    if (!split.bill && split.amount <= 0) isValid = false;
    if (split.bill && this.props.config.different_amount_reason_required) {
      if (this.amount(split.bill) != split.amount && !split.amount_reason) {
        isValid = false;
      }
    } else if (!split.bill && this.props.config.pay_to_account_comment_required) {
      if (!split.pay_to_account_comment) {
        isValid = false;
      }
    }
    return isValid;
  }

  isPlastiqPayMethod = () => {
    const { payment } = this.props;
    const payMethodType = payment.pay_method.type;
    return payMethodType === "PlastiqPayMethod";
  }

  confirmPayment(payment, opts = {}) {
    this.props.clearErrors();
    this.props.savePayment(this.props.payment);
  }

  submitPayment() {
    const {
      config,
      togglePlastiqConfirmationModal,
      confirmPayment,
      payment
    } = this.props;
    const { pay_method_config } = config;
    const plastiqConfirmationEnabled =
      pay_method_config.plastiq_confirmation_notice_enabled;
    if (this.isPlastiqPayMethod() && plastiqConfirmationEnabled) {
      togglePlastiqConfirmationModal();
    } else {
      this.setState({ paymentAuthorizationChecked: false },
        () => confirmPayment(payment)
      );
    }
  }

  onPaymentAuthChange(event) {
    this.setState({ paymentAuthorizationChecked: event.target.checked });
  }

  printPayment() {
    window.location = PaymentAPI.exportUrl(this.props.payment, 'pdf');
  }

  sidebar() {
    const hiddenClass = (this.props.currentStep == 'details') ? '' : 'd-none';

    if (this.props.abilities.manage_portal_payment && !this.props.loading) {
      return (
        <aside className={`content-sidebar ${hiddenClass}`}>
          <div className="card payment-details-panel side-panel">
            <div className="card-body">
              {this.paymentForm()}
            </div>
          </div>
        </aside>
      );
    }
  }

  tabsSection() {
    if (this.props.abilities.manage_portal_payment) {
      return (
        <section>
          <TabSet>
            <Tab
              show
              active={this.props.activeTab == "bills"}
              label={this.props.i18n.portal.payer.payment.pay_invoice}
              onSelect={this.billsTabSelected} />
            {this.payToAccountTab()}
          </TabSet>
        </section>
      );
    }
  }

  payToAccountTab() {
    if (this.props.config.pay_to_account_enabled) {
      return (
        <Tab
          show
          active={this.props.activeTab == "accounts"}
          label={this.props.i18n.portal.payer.payment.pay_to_account}
          onSelect={this.accountsTabSelected} />
      );
    }
  }

  billsTabSelected() {
    this.props.setActiveTab('bills');
  }

  accountsTabSelected() {
    this.props.setActiveTab("accounts");
  }

  // --- BILLS TABLE
  billsTable() {
    const canManagePayments = this.props.abilities.manage_portal_payment;
    const heading = canManagePayments ? this.props.i18n.portal.payer.payment.select_invoices : this.props.i18n.common.invoices;
    if (this.props.activeTab == "bills") {
      return (
        <BillsTable
          amount={this.amount}
          data={this.props.bills.data}
          columns={this.props.config.feature.portal_bills_make_payment_columns}
          heading={heading}
          isDiscountExpired={this.isDiscountExpired}
          onPage={this.pageBills}
          config={this.props.config}
          onSort={this.sortBills}
          onFilter={this.filterBills}
          filterQuery={this.props.billsQuery}
          onSelectAll={this.selectAllBills}
          onSelectAllDue={this.selectAllDueBills}
          disableRowClick={this.disableBillRowClick}
          onExportCsv={this.exportBillsCsv}
          onExportPdf={this.exportBillsPdf}
          onRowClick={this.billRowClicked}
          showBillDocsLink={this.props.config.bill_document_retriever_enabled}
          selectEnabled={canManagePayments}
          onBillDocsLinkClick={this.props.fetchBillDocuments}
          isRowSelected={this.isBillSelected}
          i18n={this.props.i18n}
          content={this.props.content}
          accounts={this.props.accounts}
          clearAccounts={this.clearBillsFilterAccounts}
          fetchAccounts={this.fetchBillsFilterAccounts}
          onAddAccountFilter={this.addBillsAccountFilter}
          onRemoveAccountFilter={this.removeBillsAccountFilter}
          filterLabels={this.props.billFilters} />
      );
    }
  }

  addBillsAccountFilter(account) {
    const filterExists = this.props.billFilters.find((filter) => {
      return filter.data.id == account.id;
    });
    if (!filterExists) {
      const label = `${account.external_key} | ${account.payer.name}`;
      this.props.addBillFilter({
        label,
        data: account
      });
    }
  }

  removeBillsAccountFilter(filter) {
    this.props.removeBillFilter(filter);
  }

  clearBillsFilterAccounts() {
    this.filterAccounts();
  }

  fetchBillsFilterAccounts(params = {}) {
    this.props.setAccountsQuery(params.query);
    this.props.setAccountsSortOrder(params.sort_order);
    this.props.setAccountsPage(params.page);
  }

  pageBills(data) {
    this.props.fetchBills({
      page: data.selected,
      query: this.props.billsQuery,
      sort_order: this.props.billsSortOrder,
      receivable_account_ids: this.getFilteredBillAccountIds()
    });
  }

  pageSplits(data) {
    this.props.fetchSplits({
      paymentId: this.props.payment.id,
      page: data.selected,
      query: this.props.splitsQuery,
      sort_order: this.props.splitsSortOrder
    });
  }

  sortBills(sortOrder) {
    this.props.setBillsSortOrder(sortOrder);
    this.props.fetchBills({
      sort_order: sortOrder,
      query: this.props.billsQuery,
      receivable_account_ids: this.getFilteredBillAccountIds()
    });
  }

  sortSplits(sortOrder) {
    this.props.setSplitsSortOrder(sortOrder);
    this.props.fetchSplits({
      paymentId: this.props.payment.id,
      sort_order: sortOrder,
      query: this.props.splitsQuery
    });
  }

  filterBills(query, props) {
    this.props.setBillsQuery(query);

    this.props.fetchBills({
      query,
      sort_order: this.props.billsSortOrder,
      receivable_account_ids: this.getFilteredBillAccountIds(props)
    });
  }

  filterSplits(query, props) {
    this.props.setSplitsQuery(query);

    this.props.fetchSplits({
      paymentId: this.props.payment.id,
      query,
      sort_order: this.props.splitsSortOrder,
    });
  }

  getFilteredBillAccountIds(props) {
    const filters = (props || this.props).billFilters;
    return filters.map((filter) => {
      return filter.data.id;
    });
  }

  isBillSelected(bill) {
    if (this.props.splitsData.bill_ids) {
      return this.props.splitsData.bill_ids.find((billId) => {
        return billId == bill.id;
      });
    }
  }

  disableBillRowClick() {
    if (this.props.splitsData.portal_splits) {
      return this.props.splitsData.portal_splits.find((split) => {
        return split.loading == true;
      });
    }
  }

  billRowClicked(bill) {
    if (this.props.abilities.manage_portal_payment && !bill.view_only && !this.disableBillRowClick()) {
      if (bill.has_related_bills) {
        this.toggleRelatedBillsModal(bill);
      } else {
        if (this.isBillSelected(bill)) {
          this.removeBill(bill);
        }
        else {
          this.addBill(bill);
        }
      }
    }
  }


  toggleRelatedBillsModal(bill) {
    this.setState({
      billForRelation: bill,
      isRelatedBillsModalOpen: !this.state.isRelatedBillsModalOpen
    });
  }

  relatedBillsModal() {
    return (
      <Modal
        showClose
        showConfirm
        isOpen={this.state.isRelatedBillsModalOpen}
        toggle={this.toggleRelatedBillsModal}
        message={this.props.content.related_bills_modal_message}
        title={this.props.content.related_bills_modal_title}
        closeLabel={this.props.i18n.common.cancel}
        confirmLabel={this.props.i18n.common.confirm}
        onConfirm={this.closeRelatedBillsModal} />
    );
  }

  closeRelatedBillsModal() {
    this.setState({
      isRelatedBillsModalOpen: false
    }, this.selectRelatedBills());
  }

  selectRelatedBills() {
    this.props.saveSplits({
      paymentId: this.props.payment.id,
      select_related: true,
      bill_for_relation_id: this.state.billForRelation.id,
      receivable_account_ids: this.getFilteredBillAccountIds(),
      batch_create: true,
    });
  }

  selectAllBills() {
    if (this.props.bills.data.total_payable_bills == 0) return;
    this.props.saveSplits({
      paymentId: this.props.payment.id,
      query: this.props.billsQuery,
      receivable_account_ids: this.getFilteredBillAccountIds(),
      batch_create: true,
      all: true
    });
  }

  selectAllDueBills() {
    if (this.props.bills.data.total_payable_due_bills == 0) return;
    this.props.saveSplits({
      paymentId: this.props.payment.id,
      query: this.props.billsQuery,
      receivable_account_ids: this.getFilteredBillAccountIds(),
      batch_create: true,
      all_due: true
    });
  }

  exportBillsCsv() {
    if (this.props.bills.data.total_bills > 0) {
      window.location = BillAPI.CsvExportUrl({
        query: this.props.billsQuery,
        receivable_account_ids: this.getFilteredBillAccountIds()
      });
    }
  }

  exportBillsPdf() {
    if (this.props.bills.data.total_bills > 0) {
      window.location = BillAPI.PdfExportUrl({
        query: this.props.billsQuery,
        receivable_account_ids: this.getFilteredBillAccountIds()
      });
    }
  }

  addBills(bills) {
    let hasRestrictedAccounts = false;
    let billsToAdd = [];

    bills.forEach((bill) => {
      if (!bill.view_only) {
        if (this.isMixKeyRestricted(bill.receivable_account)) {
          hasRestrictedAccounts = true;
        }
        else {
          if (!this.isBillSelected(bill)) {
            billsToAdd.push(bill);
          }
        }
      }
    });

    if (billsToAdd.length > 0) {
      this.createBillsFromSplits(billsToAdd);
    }

    if (hasRestrictedAccounts) {
      this.props.toggleMixKeyModal();
    }
  }

  createBillsFromSplits(bills) {
    this.props.saveSplits(this.props.payment, bills);
  }

  addBill(bill) {
    if (!this.isBillSelected(bill)) {
      const split = {
        bill,
        bill_id: bill.id,
        receivable_account_id: bill.receivable_account.id,
        amount: this.amount(bill)
      };
      this.addSplit(split);
    }
  }

  amount(bill) {
    return this.isDiscountExpired(bill) ? bill.due_amount : bill.amount;
  }

  isDiscountExpired(bill) {
    if (!bill || !bill.discount_date) return false;
    if (!bill.discount_amount || bill.discount_amount == 0) return false;

    const scheduledDate = moment(this.props.payment.scheduled_date);
    return scheduledDate.isAfter(bill.discount_date);
  }

  removeBill(bill) {
    const split = this.props.splitsData.portal_splits.find((split) => {
      return split.bill && split.bill.id == bill.id;
    });
    if (split && split.id) this.props.deleteSplit(this.props.payment, split);
  }
  // ---

  // --- ACCOUNTS TABLE
  accountsTable() {
    if (this.props.activeTab == "accounts") {
      return (
        <AccountsTable
          selectable
          useExternal
          showActions
          data={this.props.payToAccounts}
          columns={this.props.config.feature.portal_receivable_accounts_make_payment_columns}
          heading={this.props.i18n.portal.payer.payment.select_accounts}
          onRowClick={this.accountRowClicked}
          isRowSelected={this.isAccountSelected}
          filterPlaceholder={this.props.i18n.portal.accounts_table.filter_accounts}
          filterQuery={this.props.accountsQuery}
          i18n={this.props.i18n}
          content={this.props.content}
          actions={this.accountsActions()}
          externalSetFilter={this.filterPayToAccounts}
          externalSetPage={this.pagePayToAccounts}
          externalChangeSort={this.sortPayToAccounts} />
      );
    }
  }

  accountsActions() {
    return (
      <div>
        {this.uploadDocButton()}
      </div>
    );
  }

  // -- UPLOAD DOCUMENTS

  uploadDocButton() {
    if (this.props.config.document_uploads_enabled) {
      return (
        <Button
          className="action-btn"
          icon="plus-circle"
          onClick={this.openAccountDocUploader}
        >
          {this.props.i18n.common.add_documents}
        </Button>
      );
    }
  }

  openAccountDocUploader() {
    this.setState({
      docUploadContext: "account"
    }, this.toggleDocsUploadModal);
  }

  openSplitDocUploader() {
    this.setState({
      docUploadContext: "split"
    }, this.toggleDocsUploadModal);
  }

  toggleDocsUploadModal() {
    this.setState({
      isDocsModalOpen: !this.state.isDocsModalOpen
    });
  }

  docsModal() {
    return (
      <Modal
        showClose
        showConfirm
        isOpen={this.state.isDocsModalOpen}
        toggle={this.toggleDocsUploadModal}
        message={this.documentUploader()}
        title={this.props.i18n.portal.payer.payment.upload_documents}
        closeLabel={this.props.i18n.common.cancel}
        confirmLabel={this.props.i18n.common.confirm}
        onConfirm={this.closeDocUploader} />
    );
  }

  closeDocUploader() {
    this.setState({
      isDocsModalOpen: false,
      docUploadContext: null
    }, this.uploadDocs());
  }

  uploadDocs() {
    let files = {};
    let uniqueFiles = this.state.docs.filter((doc) => {
      if (files[doc.content]) {
        return false;
      }
      files[doc.content] = true;
      return true;
    });

    for (let i = 0; i < uniqueFiles.length; i++) {
      if (this.state.docUploadContext == 'account') {
        this.props.createPortalDocument(this.props.payment, uniqueFiles[i]);
      } else if (this.state.docUploadContext == 'split') {
        this.props.createPortalDocument(this.props.payment, uniqueFiles[i], this.props.selectedSplit.id);
      }
    }
  }

  addDocs(files) {
    let newDocs = [...this.state.docs, ...files];
    this.setState({
      docs: files
    });
  }

  documentUploader() {
    return (
      <DocumentUploader
        addDocs={this.addDocs}
        payment={this.props.payment}
        i18n={this.props.i18n}
        content={this.props.content}
      />
    );
  }

  // ---

  fetchPayToAccounts = (params = {}) => {
    this.props.fetchPayToAccounts(this.props.currentUser.portal_user, {
      ...params
    });
  }

  filterPayToAccounts = (query) => {
    this.fetchPayToAccounts({
      query,
      sort_order: this.props.accountsSortOrder
    });
  }

  pagePayToAccounts = (page) => {
    this.fetchPayToAccounts({
      page,
      query: this.props.accountsQuery,
      sort_order: this.props.accountsSortOrder
    });
  }

  sortPayToAccounts = (sortOrder) => {
    this.props.setAccountsSortOrder(sortOrder);
    this.fetchPayToAccounts({
      sort_order: sortOrder,
      query: this.props.accountsQuery
    });
  }

  filterAccounts(query) {
    this.props.fetchAccounts(this.props.currentUser.portal_user, {
      query,
      sort_order: this.props.accountsSortOrder
    });
  }

  pageAccounts(page) {
    this.props.fetchAccounts(this.props.currentUser.portal_user, {
      page,
      query: this.props.accountsQuery,
      sort_order: this.props.accountsSortOrder
    });
  }

  sortAccounts(sortOrder) {
    this.props.setAccountsSortOrder(sortOrder);
    this.props.fetchAccounts(this.props.currentUser.portal_user, {
      sort_order: sortOrder,
      query: this.props.accountsQuery
    });
  }

  accountRowClicked(account) {
    if (this.isAccountSelected(account)) {
      this.removeAccount(account);
    }
    else {
      this.addAccount(account);
    }
  }

  isAccountSelected(account) {
    return this.props.splitsData.portal_splits.find((split) => {
      return !split.bill && split.receivable_account.id == account.id;
    });
  }

  addAccount(account) {
    if (!this.isAccountSelected(account)) {
      const split = {
        receivable_account: account,
        receivable_account_id: account.id,
        amount: 0,
        force: true
      };
      this.addSplit(split);
    }
  }

  removeAccount(account) {
    const split = this.props.splitsData.portal_splits.find((split) => {
      return (!split.bill && split.receivable_account.id == account.id);
    });
    if (split && split.id) this.props.deleteSplit(this.props.payment, split);
  }
  // ---

  // --- SPLITS TABLE
  splitsSection() {
    if (this.props.splitsData.total_portal_splits > 0) {
      return (
        <div className="card panel-themed">
          <div className="card-body">
            {this.splitsTable()}
          </div>
        </div>
      );
    }
  }

  splitsTable() {
    return (
      <SplitsTable
        billAmount={this.amount}
        entryMode
        selectable
        showDueLabel
        splits={this.props.splitsData.portal_splits}
        data={this.props.splitsData}
        onPage={this.pageSplits}
        onFilter={this.filterSplits}
        onSort={this.sortSplits}
        columns={this.props.config.feature.portal_splits_make_payment_columns}
        heading={this.props.i18n.portal.payer.payment.selected_items}
        isRowSelected={this.isSplitSelected}
        onRowClick={this.toggleSelectedSplit}
        itemsPerPage={this.props.config.pagination_size}
        actions={this.splitActions()}
        onAmountChange={this.handleSplitAmountChange}
        onAmountReasonChange={this.handleSplitAmountReasonChange}
        onAccountCommentChange={this.handleSplitAccountCommentChange}
        onSplitChange={this.handleSplitChange}
        showBillExternalKeyAsLink={this.props.config.bill_document_retriever_enabled}
        onBillExternalKeyClick={this.props.fetchBillDocuments}
        i18n={this.props.i18n}
        content={this.props.content}
        config={this.props.config}
      />
    );
  }

  getSplitIndex(splits, split) {
    return splits.findIndex((s) => {
      return split.id == s.id;
    });
  }

  toggleSelectedSplit(split) {
    if (this.props.selectedSplit == split) {
      this.props.setSelectedSplit(null);
    } else {
      this.props.setSelectedSplit(split);
    }
  }

  isSplitSelected(split) {
    if (this.props.selectedSplit) {
      return split.id == this.props.selectedSplit.id;
    }
  }

  splitActions() {
    return (
      <span>
        {this.addSplitDocAction()}{' '}
        {this.removeSplitAction()}{' '}
        {this.removeAllAction()}
      </span>
    );
  }

  addSplitDocAction() {
    if (this.props.selectedSplit != null && this.props.config.document_uploads_enabled) {
      return (
        <span>
          <Button icon="plus-circle" onClick={this.openSplitDocUploader}>
            {this.props.i18n.common.add_documents}
          </Button>
        </span>
      );
    }
  }

  removeSplitAction() {
    const handleRemove = this.removeSelectedSplit.bind(this);
    if (this.props.selectedSplit != null) {
      return (
        <span>
          <Button icon="minus-circle" onClick={handleRemove}>
            {this.props.i18n.common.remove}
          </Button>
        </span>
      );
    }
  }

  removeAllAction() {
    const handleRemove = this.props.toggleRemoveAllConfirmationModal;
    return (
      <span>
        <Button
          className="remove-all-bills"
          icon="minus-circle"
          onClick={handleRemove}
        >
          {` ${this.props.i18n.common.remove_all}`}
        </Button>
      </span>
    );
  }

  confirmationModal(message, heading, buttonText, action, isOpen, toggle) {
    return (
      <ConfirmModal
        i18n={this.props.i18n}
        message={message}
        heading={heading}
        buttonText={buttonText}
        action={action}
        isOpen={isOpen}
        toggle={toggle}
      />
    );
  }

  removeAllConfirmationModal() {
    const {
      isRemoveAllConfirmationModalOpen,
      i18n,
      toggleRemoveAllConfirmationModal,
    } = this.props;
    const { portal, common } = i18n;
    const { modals } = portal;
    const message = modals.confirm_remove_all_message;
    const heading = modals.confirm_remove_header;
    const buttonText = common.remove_all;
    const action = this.removeAllSplits;
    const isOpen = isRemoveAllConfirmationModalOpen;
    const toggle = toggleRemoveAllConfirmationModal;

    return this.confirmationModal(
      message,
      heading,
      buttonText,
      action,
      isOpen,
      toggle
    );
  }

  plastiqConfirmationModal() {
    const {
      isPlastiqConfirmationModalOpen,
      i18n,
      togglePlastiqConfirmationModal,
    } = this.props;
    const message = this.props.content.plastiq_confirmation_notice;
    const heading = i18n.common.confirmation;
    const buttonText = i18n.common.continue;
    const action = this.plastiqConfirmation;
    const isOpen = isPlastiqConfirmationModalOpen;
    const toggle = togglePlastiqConfirmationModal;

    return this.confirmationModal(
      message,
      heading,
      buttonText,
      action,
      isOpen,
      toggle
    );
  }

  removeSelectedSplit() {
    const selectedSplit = this.props.selectedSplit;
    this.props.setSelectedSplit(null);
    this.props.deleteSplit(this.props.payment, selectedSplit);
  }

  removeAllSplits() {
    this.props.setSelectedSplit(null);
    this.props.toggleRemoveAllConfirmationModal();
    this.props.deleteAllSplits(this.props.payment);
  }

  plastiqConfirmation() {
    const {
      togglePlastiqConfirmationModal,
      confirmPayment,
      payment
    } = this.props;

    this.setState({ paymentAuthorizationChecked: false }, () => {
      togglePlastiqConfirmationModal();
      confirmPayment(payment);
    });
  }

  addSplit(split) {
    const account = this.getSplitAccount(split);

    if (this.isMixKeyRestricted(account)) {
      this.props.toggleMixKeyModal();
    }
    else {
      this.addConfirmedSplit(split);
    }
  }

  checkForCcRestrictions(splits) {
    if (this.ccFilteringEnabled() && this.hasNonCreditCardSplits(splits) && this.hasCreditCardSplits(splits)) {
      this.props.toggleCreditCardModal(true);
    }
  }


  addConfirmedSplit(split) {
    this.props.saveSplit(this.props.payment, split);
  }

  getSplitAccount(split) {
    return split.bill ? split.bill.receivable_account : split.receivable_account;
  }

  // --- CREDIT CARD RESTRICTION
  isCreditCardRestricted(account) {
    const { portal_splits } = this.props.splitsData;
    if (portal_splits.length && this.ccFilteringEnabled() && !this.hasNonCreditCardSplits(portal_splits)) {
      const firstSplit = portal_splits[0];
      const firstAccount = this.getSplitAccount(firstSplit);
      return firstAccount.credit_card_enabled && !account.credit_card_enabled;
    }
  }

  ccFilteringEnabled() {
    const ccEnabled = this.props.config.pay_method_config.cc_enabled;
    const ccFilteringEnabled = this.props.config.cc_filtering_enabled;
    return ccEnabled && ccFilteringEnabled;
  }

  hasCreditCardSplits(splits) {
    if (splits) {
      return splits.filter((split) => {
        return this.getSplitAccount(split).credit_card_enabled;
      }).length;
    }
  }

  hasNonCreditCardSplits(splits) {
    if (splits) {
      return splits.filter((split) => {
        return !this.getSplitAccount(split).credit_card_enabled;
      }).length;
    }
  }

  creditCardRestrictionModal() {
    return (
      <Modal
        toggle={this.props.toggleCreditCardModal}
        isOpen={this.props.isCreditCardModalOpen}
        message={this.props.content.non_credit_card_account_notice}
        title={this.props.i18n.portal.modals.confirmation.title}
        actions={this.creditCardRestrictionModalActions()} />
    );
  }

  creditCardRestrictionModalActions() {
    return (
      <>
        <Button onClick={this.rejectNonCreditCardPayment} size="">
          {this.props.i18n.common.cancel}
        </Button>
        <Button
          color="primary"
          onClick={this.confirmNonCreditCardPayment}
          size=""
        >
          {this.props.i18n.common.confirm}
        </Button>
      </>
    );
  }

  rejectNonCreditCardPayment() {
    this.props.toggleCreditCardModal(false);
    if (!this.props.confirmationSplit) {
      this.props.deleteAllSplits(this.props.payment);
    }
  }

  confirmNonCreditCardPayment() {
    if (this.props.confirmationSplit) {
      this.addConfirmedSplit(this.props.confirmationSplit);
    }
    this.props.toggleCreditCardModal(false);
    this.props.setConfirmationSplit(null);
  }

  // --- MIX KEY RESTRICTION
  isMixKeyRestricted(account) {
    const { portal_splits } = this.props.splitsData;
    if (this.props.config.mix_key_restriction_enabled && portal_splits.length) {
      const firstAccount = this.getSplitAccount(portal_splits[0]);
      return account.mix_key != firstAccount.mix_key;
    }
  }

  mixKeyRestrictionModal() {
    return (
      <Modal
        showClose
        isOpen={this.props.isMixKeyModalOpen}
        toggle={this.props.toggleMixKeyModal}
        message={this.props.content.mix_key_restriction_validation}
        closeLabel={this.props.i18n.common.close} />
    );
  }
  // ---

  billDocumentsModal() {
    return (
      <BillDocumentsModal
        isOpen={this.props.billDocuments != null}
        toggle={this.props.clearBillDocuments}
        billDocuments={this.props.billDocuments}
        onDocumentClick={this.downloadBillDocument}
        fetchBillDocuments={this.props.fetchBillDocuments}
        content={this.props.content}
        i18n={this.props.i18n} />
    );
  }

  downloadBillDocument(billDocument) {
    window.location = BillDocumentAPI.downloadUrl(billDocument);
  }

  isCreditCardEnabled(splits) {
    const enabled = this.props.config.pay_method_config.cc_enabled;
    const hasAbility = this.props.abilities.manage_cc_pay_method;
    const disqualified = this.ccFilteringEnabled() && this.hasNonCreditCardSplits(splits);
    return enabled && hasAbility && !disqualified;
  }

  detailsSection() {
    if (this.props.currentStep == "details" && this.props.payment) {
      return (
        <div>
          {this.formErrors()}
          <section>
            <div className="card panel-themed">
              <div className="card-body">
                {this.tabsSection()}
                {this.billsTable()}
                {this.accountsTable()}
              </div>
            </div>
            {this.splitsSection()}
          </section>
        </div>
      );
    }
  }

  editDetails() {
    this.props.setCurrentStep('details');
  }

  confirmSection() {
    const { portal_splits } = this.props.splitsData;
    if (this.props.currentStep == "confirm") {
      const translations = this.props.i18n.portal.payer.payment;
      const confirmText = translations.confirm_payment;
      const detailsText = translations.payment_details;
      const submitText = translations.submit_payment;
      const plastiqText = translations.continue_to_plastiq;
      const isPlastiq = this.props.payment.pay_method.type == "PlastiqPayMethod";
      const buttonText = isPlastiq ? plastiqText : submitText;
      const headerText = isPlastiq ? detailsText : confirmText;
      const paymentAuthorizationEnabled = this.props.config.payment_authorization_checkbox_enabled;
      const paymentNeedsAuthorization = paymentAuthorizationEnabled && !this.state.paymentAuthorizationChecked;
      const submitDisabled = this.props.submitting || paymentNeedsAuthorization;
      return (
        <section className="confirm-payment">
          <div className="card panel-themed">
            <div className="card-body">
              <p className="centered lead">
                {headerText}
              </p>

              <PaymentDetails
                payment={this.props.payment}
                splits={portal_splits}
                i18n={this.props.i18n}
                config={this.props.config}
                content={this.props.content} />

              <section className="selected-items">
                <SplitsTable
                  splits={portal_splits}
                  onPage={this.pageSplits}
                  onFilter={this.filterSplits}
                  data={this.props.splitsData}
                  onSort={this.sortSplits}
                  columns={this.props.config.feature.portal_splits_make_payment_columns}
                  heading={this.props.i18n.portal.payer.payment.selected_items}
                  itemsPerPage={this.props.config.pagination_size}
                  showBillExternalKeyAsLink={this.props.config.bill_document_retriever_enabled}
                  onBillExternalKeyClick={this.props.fetchBillDocuments}
                  i18n={this.props.i18n}
                  config={this.props.config}
                  scheduledDate={moment(this.props.payment.scheduled_date)}
                  content={this.props.content} />
              </section>

              <section className="confirmation-actions">
                <Button
                  color="primary"
                  icon="edit"
                  onClick={this.editDetails}
                >
                  {this.props.i18n.portal.payer.payment.edit_details}
                </Button>
                {this.paymentAuthorizationContent()}
                <div className="actions">
                  <Button
                    color="primary"
                    disabled={submitDisabled}
                    onClick={this.submitPayment}
                    size="lg"
                  >
                    {buttonText}
                  </Button>
                </div>
              </section>
            </div>
          </div>
        </section>
      );
    }
  }

  paymentAuthorizationContent() {
    if (!this.props.config.payment_authorization_checkbox_enabled) return null;

    const label = this.props.content.payment_authorization_checkbox_label;
    const content = this.props.content.payment_authorization_checkbox_content;
    return (
      <Checkbox
        name="paymentAuthorizationCheckbox"
        label={label}
        content={content}
        onChange={this.onPaymentAuthChange} />
    );
  }

  formErrors() {
    return (
      <FormErrors
        errors={this.props.errors}
        dismiss={this.props.clearErrors} />
    );
  }

  doneSection() {
    if (this.props.currentStep == "done") {
      return (
        <section className="confirmation">
          <div className="card panel-themed">
            <div className="card-body">
              <PaymentConfirmationMessage
                payment={this.props.payment}
                content={this.props.content} />

              <PaymentDetails
                payment={this.props.payment}
                i18n={this.props.i18n}
                config={this.props.config}
                content={this.props.content} />

              <div className="actions">
                {this.sendEmailConfirmationButton()}{' '}
                {this.printPaymentButton()}{' '}
                <Button onClick={this.goToDashboard} size="lg">
                  {this.props.i18n.common.done}
                </Button>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }

  plastiqSection() {
    if (this.props.currentStep == "plastiq") {
      window.location = this.props.payment.plastiq_request_url;
      return (
        <section className="confirmation">
          <div className="card panel-themed">
            <div className="card-body">
              <p className="centered lead">
                {this.props.i18n.portal.payer.payment.continuing_to_plastiq}
              </p>
              <Spinner centered size={'medium'} />
            </div>
          </div>
        </section>
      );
    }
  }

  sendEmailConfirmationButton() {
    return (
      <Button
        className="email"
        icon="envelope"
        onClick={this.toggleEmailConfirmationModal}
        size="lg"
      >
        {this.props.i18n.common.send_email_confirmation}
      </Button>
    );
  }

  toggleEmailConfirmationModal() {
    this.setState({
      isEmailConfirmationModalOpen: !this.state.isEmailConfirmationModalOpen
    });
  }

  emailConfirmationModal() {
    return (
      <EmailConfirmationModal
        isOpen={this.state.isEmailConfirmationModalOpen}
        toggle={this.toggleEmailConfirmationModal}
        payment={this.props.payment}
        content={this.props.content}
        sendEmailConfirmation={this.props.sendEmailConfirmation}
        i18n={this.props.i18n} />
    );
  }

  printPaymentButton() {
    if (!this.props.payment.is_failed) {
      return (
        <Button
          className="print"
          icon="print"
          onClick={this.printPayment}
          size="lg"
        >
          {this.props.i18n.common.print}
        </Button>
      );
    }
  }

  loading() {
    if (this.props.loading) {
      return (
        <div className="content-main">
          <div className="card panel-themed">
            <div className="card-body">
              <Spinner
                centered
                size={'medium'} />
            </div>
          </div>
        </div>
      );
    }
  }

  content() {
    if (!this.props.loading) {
      return (
        <div className="content-main">
          {this.detailsSection()}
          {this.confirmSection()}
          {this.doneSection()}
          {this.plastiqSection()}
        </div>
      );
    }
  }

  heading() {
    const heading = this.props.abilities.manage_portal_payment ?
      this.props.i18n.portal.payer.payment.make_payment :
      this.props.i18n.portal.dashboard.view_invoices;
    return (
      <div className="content-main">
        <h1 className="section-heading">
          {heading}
        </h1>
      </div>
    );
  }

  billPaymentContent() {
    return (
      <div id="make-bill-payment">
        {this.heading()}
        {this.loading()}
        <div className="row">
          {this.content()}
          {this.sidebar()}
        </div>
        {this.mixKeyRestrictionModal()}
        {this.creditCardRestrictionModal()}
        {this.billDocumentsModal()}
        {this.removeAllConfirmationModal()}
        {this.plastiqConfirmationModal()}
        {this.docsModal()}
        {this.emailConfirmationModal()}
        {this.relatedBillsModal()}
      </div>
    );
  }

  overlayMessage = () => {
    return this.props.showRemoveAllLoadingMessage ?
      this.props.i18n.portal.payer.payment.remove_all_overlay_message :
      this.props.i18n.portal.payer.payment.add_all_overlay_message;
  }

  render() {

    return (
      <LoadingOverlay
        active={this.props.showLoadingOverlay}
        fadeSpeed={0}
        spinner
        text={this.overlayMessage()}
      >
        {this.billPaymentContent()}
      </LoadingOverlay>
    );
  }
}

BillPaymentScreen.propTypes = {
  abilities: PropTypes.object,
  accounts: PropTypes.object,
  accountsPage: PropTypes.number,
  accountsQuery: PropTypes.string,
  accountsSortOrder: PropTypes.string,
  activeTab: PropTypes.string,
  addBillFilter: PropTypes.func.isRequired,
  billDocuments: PropTypes.object,
  billFilters: PropTypes.array.isRequired,
  bills: PropTypes.object,
  billsQuery: PropTypes.string,
  billsSortOrder: PropTypes.string,
  clearBillDocuments: PropTypes.func,
  clearBillFilters: PropTypes.func.isRequired,
  clearDocsAdded: PropTypes.func,
  clearErrors: PropTypes.func.isRequired,
  clearSavedPayMethod: PropTypes.func,
  clearSavePayMethodErrors: PropTypes.func,
  clearSelectedBills: PropTypes.func,
  config: PropTypes.object,
  confirmationSplit: PropTypes.object,
  confirmPayment: PropTypes.func.isRequired,
  content: PropTypes.object,
  createPortalDocument: PropTypes.func.isRequired,
  createStripeIntent: PropTypes.func,
  creditCardEnabled: PropTypes.bool,
  currentStep: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  deleteAllSplits: PropTypes.func,
  deletePortalDocument: PropTypes.func,
  deleteSplit: PropTypes.func,
  docsAdded: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  fetchAccounts: PropTypes.func,
  fetchBillDocuments: PropTypes.func,
  fetchBills: PropTypes.func,
  fetchPayMethods: PropTypes.func,
  fetchPayToAccounts: PropTypes.func,
  fetchSplits: PropTypes.func,
  fetchStagedPayment: PropTypes.func,
  hasExpiredDiscount: PropTypes.bool,
  i18n: PropTypes.object,
  isPlastiqConfirmationModalOpen: PropTypes.bool,
  isRemoveAllConfirmationModalOpen: PropTypes.bool,
  isCreditCardModalOpen: PropTypes.bool,
  isMixKeyModalOpen: PropTypes.bool,
  loading: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  params: PropTypes.object,
  payment: PropTypes.object,
  payments: PropTypes.object,
  payMethodErrors: PropTypes.array,
  payMethods: PropTypes.array,
  payToAccounts: PropTypes.object.isRequired,
  removeAllSplitsPending: PropTypes.func,
  removeBillFilter: PropTypes.func.isRequired,
  savedPayMethod: PropTypes.object,
  savePayment: PropTypes.func,
  savePayMethod: PropTypes.func,
  saveSplit: PropTypes.func,
  saveSplits: PropTypes.func.isRequired,
  selectBills: PropTypes.func,
  selectDueBills: PropTypes.func,
  selectedSplit: PropTypes.object,
  sendEmailConfirmation: PropTypes.func,
  setAccountsPage: PropTypes.func.isRequired,
  setAccountsQuery: PropTypes.func.isRequired,
  setAccountsSortOrder: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setBillsQuery: PropTypes.func.isRequired,
  setBillsSortOrder: PropTypes.func.isRequired,
  setConfirmationSplit: PropTypes.func.isRequired,
  setCreditCardEnabled: PropTypes.func,
  setCurrentStep: PropTypes.func.isRequired,
  setPayment: PropTypes.func.isRequired,
  setPaymentErrors: PropTypes.func,
  setPayMethodErrors: PropTypes.func,
  setSelectedSplit: PropTypes.func.isRequired,
  setSplit: PropTypes.func.isRequired,
  setSplitsQuery: PropTypes.func.isRequired,
  setSplitsSortOrder: PropTypes.func.isRequired,
  setSplitsValid: PropTypes.func,
  setViewOnly: PropTypes.func.isRequired,
  showLoadingOverlay: PropTypes.bool,
  showRemoveAllLoadingMessage: PropTypes.bool,
  splitUpdating: PropTypes.bool,
  splits: PropTypes.array,
  splitsData: PropTypes.object,
  splitsLastModified: PropTypes.object,
  splitsQuery: PropTypes.string,
  splitsSortOrder: PropTypes.string,
  splitsValid: PropTypes.bool,
  stripeIntent: PropTypes.string,
  submitting: PropTypes.bool,
  toggleRemoveAllConfirmationModal: PropTypes.func.isRequired,
  togglePlastiqConfirmationModal: PropTypes.func.isRequired,
  toggleCreditCardModal: PropTypes.func.isRequired,
  toggleMixKeyModal: PropTypes.func.isRequired,
  updatePayment: PropTypes.func.isRequired,
  updateSplit: PropTypes.func.isRequired,
};

export default BillPaymentScreen;
