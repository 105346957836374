import * as types from '../types';
import initialState from './initialState';

export default function portalAccountsReducer(state = initialState.portalAccounts, action) {
  switch (action.type) {
    case types.FETCH_PORTAL_ACCOUNTS_FULFILLED: {
      return { ...state, data: action.payload.data };
    }
    case types.CLEAR_PORTAL_ACCOUNTS: {
      return {};
    }
    default:
      return state;
  }
}
