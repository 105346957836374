import { http } from 'shared/api';

export default class Split {

  static fetchSplits(params = {}) {
    return http.get(`portal_payments/${params.paymentId}/portal_splits`, {
      params
    });
  }

  static create(payment, split) {
    return http.post(`portal_payments/${payment.id}/portal_splits`, {
      portal_split: {
        amount: split.amount,
        bill_id: split.bill_id,
        receivable_account_id: split.receivable_account_id,
        force: split.force
      }
    });
  }

  static batch_create(params = {}) {
    return http.post(`portal_payments/${params.paymentId}/portal_splits`, {
      ...params
    });
  }


  static update(payment, split, attributes = {}) {
    const amount = "amount" in attributes ? attributes["amount"] : split.amount;
    return http.put(`portal_payments/${payment.id}/portal_splits/${split.id}`, {
      portal_split: {
        amount: amount,
        amount_reason: split.amount_reason,
        pay_to_account_comment: split.pay_to_account_comment
      }
    });
  }

  static save(payment, split) {
    const save = split.id ? this.update : this.create;
    return save(payment, split);
  }

  static async delete(payment, split) {
    return http.delete(
      `portal_payments/${payment.id}/portal_splits/${split.id}`
    );
  }

  static deleteAll(payment) {
    return http.delete(
      `portal_payments/${payment.id}/portal_splits/all`
    );
  }
}
