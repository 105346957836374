import * as types from '../types';
import initialState from './initialState';

export default function createPortalAgreementReducer(state = initialState.createPortalAgreement, action) {
  switch (action.type) {
    case types.CREATE_PORTAL_AGREEMENT_FULFILLED: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
