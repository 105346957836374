import bugsnagClient from './bugsnag';
import 'bootstrap';
import React from 'react';
import { PropTypes } from 'prop-types';
import createPlugin from 'bugsnag-react';
import configureStore from 'store/configureStore';
import rootReducer from './state/reducer';
import { Provider } from 'react-redux';
import { Router, hashHistory } from 'react-router';
import routes from './routes';
import { fetchConfig } from './state/actions';
import { setAPIBaseUrl } from 'shared/api';
import FallbackErrorBoundary from './components/common/FallbackErrorBoundary';
import { ToastProvider } from 'react-toast-notifications';

const store = configureStore(rootReducer);

class App extends React.Component {
  componentWillMount() {
    setAPIBaseUrl(this.props.api_base_url);
    store.dispatch(fetchConfig());
  }

  errorBoundary() {
    return bugsnagClient ?
      bugsnagClient.use(createPlugin(React)) :
      FallbackErrorBoundary;
  }

  render() {
    const ErrorBoundary = this.errorBoundary();
    return (
      <ErrorBoundary FallbackComponent={FallbackErrorBoundary}>
        <Provider store={store}>
          <ToastProvider>
            <Router history={hashHistory} routes={routes} />
          </ToastProvider>
        </Provider>
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  api_base_url: PropTypes.string.isRequired
};

export default App;
