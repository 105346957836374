import * as types from '../../types';
import Split from '../../../api/split';

export function saveSplit(payment, split) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_SPLIT,
      payload: Split.save(payment, split),
      meta: {
        split
      }
    });
  };
}

export function saveSplits(params = {}) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_SPLITS,
      payload: Split.batch_create(params)
    });
  };
}

export function setSplitsQuery(splitsQuery) {
  return {
    type: types.SET_SPLITS_QUERY,
    payload: splitsQuery
  };
}

export function setSplitsSortOrder(splitsSortOrder) {
  return {
    type: types.SET_SPLITS_SORT_ORDER,
    payload: splitsSortOrder
  };
}

export function fetchSplits(params = {}) {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_SPLITS,
      payload: Split.fetchSplits(params)
    });
  };
}

export function deleteSplit(payment, split) {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_SPLIT,
      payload: Split.delete(payment, split),
      meta: {
        split
      }
    });
  };
}

export function deleteAllSplits(payment) {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_ALL_SPLITS,
      payload: Split.deleteAll(payment)
    });
  };
}

export function updateSplit(payment, split, attributes = {}) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_SPLIT,
      payload: Split.update(payment, split, attributes)
    });
  };
}
