import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegistrationScreen from './screen';
import * as actionCreators from '../../state/screens/registration/actions';

function mapStateToProps(state) {
  return {
    loading: state.screens.registration.loading,
    currentStep: state.screens.registration.currentStep,
    errors: state.screens.registration.errors,
    accounts: state.screens.registration.accounts,
    registration: state.screens.registration.registration
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(RegistrationScreen);
