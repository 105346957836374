import * as types from '../types';
import initialState from './initialState';

export default function createdUserReducer(state = initialState.createdUser, action) {
  switch (action.type) {
    case types.CREATE_USER_FULFILLED: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
