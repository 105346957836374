import { http } from 'shared/api';

export default class Registration {

  static create(account) {
    return http.post(`registrations`, {
      account
    });
  }
}
