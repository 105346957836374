import React from 'react';
import { PropTypes } from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../components/common/Button';

class PortalAgreementModal extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle || (() => {})}
        className="portal-agreement-modal">
        <ModalHeader>
          {this.props.agreementLabel}
        </ModalHeader>
        <ModalBody>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: this.props.agreementNotice}}>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.acceptAction}>
            {this.props.i18n.portal.modals.portal_agreement.accept}
          </Button>
          <Button color="danger" onClick={this.props.declineAction}>
            {this.props.i18n.portal.modals.portal_agreement.decline}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

PortalAgreementModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  acceptAction: PropTypes.func,
  declineAction: PropTypes.func,
  agreementNotice: PropTypes.string,
  agreementLabel: PropTypes.node,
  i18n: PropTypes.object.isRequired
};

export default PortalAgreementModal;
