import * as types from '../types';
import initialState from './initialState';

export default function billsReducer(state = initialState.bills, action) {
  switch (action.type) {
    case types.FETCH_BILLS_OVERVIEW_FULFILLED:
      return { ...state, overview: action.payload.data };
    case types.FETCH_BILLS_FULFILLED:
      return { ...state, data: action.payload.data };
    case types.CLEAR_BILLS_DATA:
      return { ...state, data: null };
    default:
      return state;
  }
}
