import * as types from '../types';
import initialState from './initialState';

export default function errorsReducer(state = initialState.errors, action) {
  switch (action.type) {
    case types.LOGIN_REJECTED: {
      return { ...state, login: action.payload.response.data.errors };
    }
    case types.CREATE_USER_REJECTED: {
      return { ...state, user: action.payload.response.data.errors };
    }
    case types.UPDATE_USER_REJECTED: {
      return { ...state, user: action.payload.response.data.errors };
    }
    case types.SAVE_PAYMENT_REJECTED: {
      return { ...state, payment: action.payload.response.data.errors };
    }
    case types.SAVE_PAY_METHOD_REJECTED: {
      return { ...state, pay_method: action.payload.response.data.errors };
    }
    case types.UPDATE_PASSWORD_REJECTED: {
      return { ...state, passwordReset: action.payload.response.data.errors };
    }
    case types.CLEAR_ERRORS: {
      let errors = { state };
      return errors;
    }
    case types.SET_ERRORS: {
      const e = action.payload.errors;
      const errors = Array.isArray(e) ? e : [e];
      return { ...state, [action.payload.key]: errors };
    }
    default:
      return state;
  }
}
