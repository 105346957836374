import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccountScreen from './screen';
import * as actionCreators from '../../state/screens/account/actions';

function mapStateToProps(state) {
  return {
    ...state.screens.account,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(AccountScreen);
