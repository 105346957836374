import React from 'react';
import { PropTypes } from 'prop-types';
import Button from '../../../components/common/Button';
import Modal from '../../../components/common/Modal';
import EmailConfirmationModal from '../../../components/common/EmailConfirmationModal';
import SplitsTable from '../../../components/common/SplitsTable';
import ExternalBillsTable from '../../../components/common/ExternalBillsTable';
import PaymentDetails from '../../../components/common/PaymentDetails';
import PaymentAPI from '../../../api/paymentAPI';
import ExternalPaymentAPI from '../../../api/externalPaymentAPI';
import Spinner from '../../../components/common/Spinner';

class PaymentDetailsModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isEmailConfirmationModalOpen: false
    };

    this.toggleEmailConfirmationModal = this.toggleEmailConfirmationModal.bind(this);
  }

  exportOptions() {
    const exportPDF = this.exportPayment.bind(this, 'pdf');
    const exportCSV = this.exportPayment.bind(this, 'csv');
    return (
      <div className="export-options">
        <div className="btn-group">
          <Button
            icon="file-pdf far"
            onClick={exportPDF}
          >
            {this.props.i18n.common.pdf}
          </Button>
          <Button
            icon="file-excel far"
            onClick={exportCSV}
          >
            {this.props.i18n.common.csv}
          </Button>
          <Button
            icon="envelope far"
            onClick={this.toggleEmailConfirmationModal}
          >
            {this.props.i18n.common.email}
          </Button>
        </div>
      </div>
    );
  }

  emailConfirmationModal() {
    return (
      <EmailConfirmationModal
        isOpen={this.state.isEmailConfirmationModalOpen}
        toggle={this.toggleEmailConfirmationModal}
        payment={this.props.payment}
        content={this.props.content}
        sendEmailConfirmation={this.props.sendEmailConfirmation}
        i18n={this.props.i18n} />
    );
  }

  toggleEmailConfirmationModal() {
    this.setState({
      isEmailConfirmationModalOpen: !this.state.isEmailConfirmationModalOpen
    });
  }

  exportPayment(format) {
    const resourceAPI = this.props.payment.external ? ExternalPaymentAPI : PaymentAPI;
    window.location = resourceAPI.exportUrl(this.props.payment, format);
  }

  detailsTable() {
    return (
      <PaymentDetails
        payment={this.props.payment}
        i18n={this.props.i18n}
        config={this.props.config}
        content={this.props.content} />
    );
  }

  splitsTable() {
    if (this.props.config.feature.bill_search_enabled && !this.props.payment.external) {
      return (
        <SplitsTable
          splits={this.props.splits}
          commentRequired={this.props.config.pay_to_account_comment_required}
          onPage={this.props.onSplitsPageChange}
          onFilter={this.props.onSplitsFilterChange}
          onSort={this.props.onSplitsSortChange}
          data={this.props.splitsData}
          columns={this.props.config.feature.portal_splits_make_payment_columns}
          heading={this.props.i18n.portal.payer.payment.selected_items}
          itemsPerPage={this.props.config.pagination_size}
          showBillExternalKeyAsLink={this.props.config.bill_document_retriever_enabled}
          onBillExternalKeyClick={this.props.fetchBillDocuments}
          i18n={this.props.i18n}
          config={this.props.config}
          content={this.props.content} />
      );
    }
  }

  externalBillsTable() {
    if (this.props.config.feature.bill_search_enabled && this.props.payment.external) {
      return (
        <ExternalBillsTable
          results={this.props.payment.external_bills}
          itemsPerPage={this.props.config.pagination_size}
          showBillExternalKeyAsLink={this.props.config.bill_document_retriever_enabled}
          onBillExternalKeyClick={this.props.fetchExternalBillDocuments}
          i18n={this.props.i18n}
          content={this.props.content}
          config={this.props.config} />
      );
    }
  }

  content() {
    if (this.props.loading) {
      return <Spinner centered size="medium" />;
    }
    else {
      return (
        <div>
          {this.exportOptions()}
          {this.detailsTable()}
          {this.splitsTable()}
          {this.externalBillsTable()}
          {this.emailConfirmationModal()}
        </div>
      );
    }
  }

  render() {
    if (this.props.payment) {
      return (
        <Modal
          showClose
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className="payment-details-modal"
          title={this.props.i18n.portal.payer.payment.payment_details}
          message={this.content()}
          closeLabel={this.props.i18n.common.close}
        />
      );
    }
    else {
      return null;
    }
  }
}

PaymentDetailsModal.propTypes = {
  payment: PropTypes.object,
  splitsData: PropTypes.object,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  fetchBillDocuments: PropTypes.func,
  onSplitsPageChange: PropTypes.func,
  onSplitsFilterChange: PropTypes.func,
  onSplitsSortChange: PropTypes.func,
  splits: PropTypes.array,
  fetchExternalBillDocuments: PropTypes.func,
  sendEmailConfirmation: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  loading: PropTypes.bool
};

export default PaymentDetailsModal;
