import * as types from '../types';
import initialState from './initialState';

export default function externalBillsReducer(state = initialState.externalBills, action) {
  switch (action.type) {
    case types.FETCH_EXTERNAL_BILLS_FULFILLED:
      return { data: action.payload.data };

    default:
      return state;
  }
}
