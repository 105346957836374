import * as types from '../types';

export const initialState = {
  isSwitchAccountModalOpen: false
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case types.TOGGLE_SWITCH_ACCOUNT_MODAL: {
      return {
        ...state,
        isSwitchAccountModalOpen: !state.isSwitchAccountModalOpen
      };
    }
    default:
      return state;
  }
}
