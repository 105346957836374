import React from 'react';
import { PropTypes } from 'prop-types';
import { Link as RouterLink } from 'react-router';
import accounting from 'accounting';
import moment from 'moment';
import AccountDetails from '../../../components/account/AccountDetails';
import AccountRecurrenceForm from '../../../components/common/AccountRecurrenceForm';
import Button from '../../../components/common/Button';
import RecurrenceSettings from '../../../components/common/RecurrenceSettings';
import ConfirmModal from '../../../components/common/ConfirmModal';
import Link from '../../../components/common/Link';

class AccountPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      showRecurrenceForm: false,
      showRecurrenceSettings: false,
      isRemoveModalOpen: false,
      isSubmitDisabled: false
    };

    this.toggleDetails = this.toggleDetails.bind(this);
    this.toggleRecurrenceForm = this.toggleRecurrenceForm.bind(this);
    this.toggleRecurrenceSettings = this.toggleRecurrenceSettings.bind(this);
    this.toggleRemoveModal = this.toggleRemoveModal.bind(this);
    this.createAccountRecurrence = this.createAccountRecurrence.bind(this);
    this.deleteRecurrence = this.deleteRecurrence.bind(this);
  }

  componentWillMount() {
    if (this.props.account.portal_recurrence) {
      this.setState({recurrence: this.props.account.portal_recurrence});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.createdAccountRecurrence) {
      this.setState({
        showRecurrenceForm: false,
        isSubmitDisabled: false
      });
    }
    if (nextProps.deletedAccountRecurrence) {
      this.setState({
        showRecurrenceSettings: false,
        isSubmitDisabled: false
      });
    }
  }

  autopayStatus(opts = {}) {
    if (this.props.account.portal_recurrence || this.state.recurrenceCreated) {
      return (
        <section className={opts.wrapperClass}>
          <mark className="autopay-message">
            {this.props.i18n.portal.dashboard.autopay_enabled}
          </mark>
        </section>
      );
    }
  }

  accountDueDate() {
    if (this.props.account.due_date) {
      return (
        <div className="due-date">
          {this.props.content.due_label}{': '}
          {moment(this.props.account.due_date).format("MM/DD/YYYY")}
        </div>
      );
    }
  }

  accountDueAmount() {
    return (
      <div className="amount">
        {accounting.formatMoney(this.props.account.due_amount)}
      </div>
    );
  }

  accountDetails() {
    if (this.state.showDetails) {
      return (
        <div className="account-details-row row">
          <div className="col-12">
            <AccountDetails {...this.props} account={this.props.account} />
          </div>
        </div>
      );
    }
  }

  recurrenceSettings() {
    if (this.state.showRecurrenceSettings) {
      return (
        <RecurrenceSettings
          recurrence={this.props.account.portal_recurrence}
          onEdit={this.toggleRecurrenceForm}
          onRemove={this.toggleRemoveModal}
          content={this.props.content}
          i18n={this.props.i18n} />
      );
    }
  }

  removeAutopay() {
    this.setState({ isRemoveModalOpen: true });
  }

  removeRecurrenceModal() {
    return (
      <ConfirmModal
        i18n={this.props.i18n}
        message={this.props.i18n.portal.modals.confirm_remove_message}
        heading={this.props.i18n.portal.modals.confirm_remove_header}
        buttonText={this.props.i18n.common.remove}
        action={this.deleteRecurrence}
        isOpen={this.state.isRemoveModalOpen}
        toggle={this.toggleRemoveModal} />
    );
  }

  deleteRecurrence() {
    this.props.deleteAccountRecurrence(this.props.account.portal_recurrence);
    this.toggleRemoveModal();
  }

  toggleRemoveModal() {
    this.setState({ isRemoveModalOpen: !this.state.isRemoveModalOpen });
  }

  recurrenceForm() {
    if (this.state.showRecurrenceForm) {
      return (
        <div className="recurrence-setup-row row">
          <div className="col-12">
            <AccountRecurrenceForm
              allowCancel
              account={this.props.account}
              recurrence={this.props.account.portal_recurrence}
              errors={this.props.accountRecurrenceErrors}
              clearErrors={this.props.clearAccountRecurrenceErrors}
              payMethods={this.props.payMethods}
              heading={this.props.i18n.portal.payer.autopay.setup_autopay}
              onSubmit={this.createAccountRecurrence}
              onCancel={this.toggleRecurrenceForm}
              content={this.props.content}
              config={this.props.config}
              disabled={this.state.isSubmitDisabled}
              i18n={this.props.i18n} />
          </div>
        </div>
      );
    }
  }

  createAccountRecurrence(recurrence) {
    this.setState({ isSubmitDisabled: true });
    recurrence.receivable_account_id = this.props.account.id;
    this.props.createAccountRecurrence(recurrence);
  }

  accountDetailsLink() {
    const linkText = this.state.showDetails ? 'hide_details' : 'view_details';
    return (
      <Link onClick={this.toggleDetails} className="details-link">
        {this.props.i18n.portal.dashboard[linkText]}
      </Link>
    );
  }

  recurrenceLink() {
    let linkText, linkIcon, clickHandler;

    if (this.props.account.portal_recurrence) {
      linkText = "autopay_settings";
      linkIcon = "cog";
      clickHandler = this.toggleRecurrenceSettings;
    }
    else {
      linkText = this.state.showRecurrenceForm ? "cancel_setup" : "setup_autopay";
      clickHandler = this.toggleRecurrenceForm;
    }

    return (
      <Link onClick={clickHandler} className="autopay-link" icon={linkIcon}>
        {this.props.i18n.portal.dashboard[linkText]}
      </Link>
    );
  }

  toggleDetails() {
    this.setState({
      showDetails: !this.state.showDetails
    });
  }

  toggleRecurrenceForm() {
    this.props.clearAccountRecurrenceErrors();
    this.setState({
      showRecurrenceForm: !this.state.showRecurrenceForm,
      showRecurrenceSettings: false
    });
  }

  toggleRecurrenceSettings() {
    this.setState({
      showRecurrenceSettings: !this.state.showRecurrenceSettings,
      showRecurrenceForm: false
    });
  }

  accountSummary() {
    return (
      <div className="account-summary-row row">
        <section className="account-summary">
          <h1 className="sr-only">{this.props.i18n.portal.ada_headers.account_summary}</h1>
          {this.accountDueAmount()}
          {this.accountDueDate()}
        </section>
        <section className="pay-now">
          <h1 className="sr-only">{this.props.i18n.portal.ada_headers.pay_now}</h1>
          {this.autopayStatus({ wrapperClass: "d-none" })}
          {this.paymentLink()}
        </section>
      </div>
    );
  }

  paymentLink() {
    return (
      <RouterLink
        to={`payer/accounts/${this.props.account.id}/payment`}
        className="pay-btn btn primary-btn">
        {this.props.i18n.portal.dashboard.pay_now}
      </RouterLink>
    );
  }

  footer() {
    return (
      <footer>
        {this.accountDetailsLink()}
        {this.recurrenceLink()}
        {this.accountDetails()}
        {this.recurrenceForm()}
        {this.recurrenceSettings()}
      </footer>
    );
  }

  render() {
    return (
      <div key={this.props.account.id} className="account-details-panel card panel-themed">
        <div className="card-body">
          <h1>
            {this.props.account.account_label}
          </h1>
          {this.autopayStatus({ wrapperClass: "autopay-message visible-xs" })}
          {this.accountSummary()}
          {this.footer()}
          {this.removeRecurrenceModal()}
        </div>
      </div>
    );
  }
}

AccountPanel.propTypes = {
  account: PropTypes.object.isRequired,
  payMethods: PropTypes.array,
  labelConfig: PropTypes.object.isRequired,
  config: PropTypes.object,
  createAccountRecurrence: PropTypes.func.isRequired,
  deleteAccountRecurrence: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  accountRecurrenceErrors: PropTypes.array,
  clearAccountRecurrenceErrors: PropTypes.func.isRequired
};

export default AccountPanel;
