import * as types from '../types';
import initialState from './initialState';

export default function deletedAccountRecurrenceReducer(state = initialState.deletedAccountRecurrence, action) {
  switch (action.type) {
    case types.DELETE_ACCOUNT_RECURRENCE_FULFILLED: {
      return action.payload.data;
    }
    case types.CLEAR_DELETED_ACCOUNT_RECURRENCE: {
      return null;
    }
    default:
      return state;
  }
}
