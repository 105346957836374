import * as types from '../types';
import initialState from './initialState';

export default function usersReducer(state = initialState.users, action) {
  switch (action.type) {
    case types.FETCH_PORTAL_USERS_FULFILLED: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
