import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SettingsScreen from './screen';
import * as actionCreators from '../../state/screens/settings/actions';

function mapStateToProps(state) {
  return {
    ...state.screens.settings,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(SettingsScreen);
