import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';
import accounting from 'accounting';
import Spinner from '../../../components/common/Spinner';

class BillsOverview extends React.Component {

  overviewAmounts() {
    const sectionClassName = this.props.config.display_due_amount_enabled ? "overview-panel-section all-open" : "overview-panel-section all-open full-width";
    return (
      <div className="row">
        {this.dueAmountSection()}
        <section className={sectionClassName}>
          <h1>{this.props.openAmountLabel}</h1>
          <div className="amount">
            {this.overviewAmount(this.props.openAmount)}
          </div>
        </section>
      </div>
    );
  }

  dueAmountSection() {
    if (this.props.config.display_due_amount_enabled) {
      return (
        <section className="overview-panel-section due">
          <h1>{this.props.dueAmountLabel}</h1>
          <div className="amount">
            {this.overviewAmount(this.props.dueAmount)}
          </div>
        </section>
      );
    }
  }

  overviewAmount(amount) {
    if (amount && !this.props.loading) {
      return accounting.formatMoney(amount);
    } else {
      return <Spinner />;
    }
  }

  paymentLink() {
    const buttonLabel = this.props.showPaymentLink ?  this.props.paymentLinkLabel : this.props.viewInvoicesLabel;
    return (
      <section className="make-payment-section">
        <Link to={this.props.paymentLinkTo} className="btn primary-btn btn-lg">
          {buttonLabel}
        </Link>
      </section>
    );
  }

  render() {
    return (
      <section className="dash-section overview-section">
        <h1 className="section-heading">
          {this.props.overviewLabel}
        </h1>
        <div className="card overview-panel panel-themed">
          <div className="card-body">
            {this.overviewAmounts()}
            {this.paymentLink()}
          </div>
        </div>
      </section>
    );
  }
}

BillsOverview.propTypes = {
  loading: PropTypes.bool,
  overviewLabel: PropTypes.string.isRequired,
  dueAmount: PropTypes.string,
  dueAmountLabel: PropTypes.string.isRequired,
  openAmount: PropTypes.string,
  openAmountLabel: PropTypes.string.isRequired,
  showPaymentLink: PropTypes.bool,
  config: PropTypes.object,
  paymentLinkLabel: PropTypes.string,
  paymentLinkTo: PropTypes.string,
  viewInvoicesLabel: PropTypes.string
};

export default BillsOverview;
