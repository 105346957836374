import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { hashHistory } from 'react-router';
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal';
import PaymentForm from '../../components/common/PaymentForm';
import AccountDetails from '../../components/account/AccountDetails';
import PaymentAPI from '../../api/paymentAPI';
import format from '../../utils/formatUtils';
import Spinner from '../../components/common/Spinner';

export default class AccountPaymentScreen extends Component {

  constructor(props) {
    super(props);
    this.submitPayment = this.submitPayment.bind(this);
    this.printPayment = this.printPayment.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
  }

  componentWillMount() {
    this.props.resetScreen();
    this.props.fetchPayMethods();
    this.props.clearSavedPayment();
    this.props.clearPaymentErrors();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.account && nextProps.account) {
      this.handleAccountChange(nextProps.account);
    }
    else if (!this.props.account) {
      this.requireAccount();
    }
  }

  handleAccountChange(account) {
    if (this.props.params.paymentID) this.setupEditPayment();
    this.props.fetchStagedPayment({
      receivable_account_id: account.id
    });
  }

  handlePaymentChange(payment) {
    this.props.setPayment(payment);
  }

  requireAccount() {
    const account = this.getAccount();
    if (account) this.props.setAccount(account);
    else this.goToDashboard();
  }

  getAccount() {
    const accounts = this.props.accounts.data ? this.props.accounts.data.accounts : [];
    const accountID = this.props.params.accountID;
    return accounts.find((account) => {
      return account.id == accountID;
    });
  }

  setupEditPayment() {
    if (this.props.payments.data) {
      const payment = this.getPayment();
      this.props.setPayment(payment);
    }
    else {
      this.goToDashboard();
    }
  }

  getPayment() {
    const paymentID = this.props.params.paymentID;
    return this.props.payments.data.payments.find((payment) => {
      return payment.id == paymentID;
    });
  }

  goToDashboard() {
    hashHistory.push('/payer');
  }

  paymentForm() {
    return (
      <PaymentForm
        showSteps
        showAmountField
        payment={this.props.payment}
        account={this.props.account}
        payMethods={this.props.payMethods}
        creditCardEnabled={this.props.config.pay_method_config.cc_enabled}
        onChange={this.handlePaymentChange}
        onSubmit={this.submitPayment}
        onSubmitPayMethod={this.props.savePayMethod}
        payMethodErrors={this.props.payMethodErrors}
        savedPayMethod={this.props.savedPayMethod}
        clearPayMethodErrors={this.props.clearSavePayMethodErrors}
        clearSavedPayMethod={this.props.clearSavedPayMethod}
        savedPayment={this.props.savedPayment}
        onDone={this.goToDashboard}
        onPrint={this.printPayment}
        errors={this.props.errors}
        onPayMethodError={this.props.setPayMethodErrors}
        clearErrors={this.props.clearPaymentErrors}
        labelClass="col-md-4"
        i18n={this.props.i18n}
        config={this.props.config}
        content={this.props.content}
        abilities={this.props.abilities}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        submitting={this.props.submitting}
        />
    );
  }

  submitPayment(payment, opts = {}) {
    this.props.savePayment(payment, {
      pay_method: opts.payMethod,
      new_pay_method: opts.newPayMethod,
      receivable_account_id: this.props.account.id
    });
  }

  printPayment() {
    window.location = PaymentAPI.exportUrl(this.props.savedPayment, 'pdf');
  }

  sidebar() {
    if (!this.props.loading) {
      return (
        <aside className="content-sidebar">
          <div className="card account-details-panel">
            <div className="card-body">
              <h1 className="account-details-header">
                {this.props.account.account_label}
              </h1>
              <div className="amount">
                {format.currency(this.props.account.due_amount)}
              </div>
              <AccountDetails
                showNotice
                content={this.props.content}
                account={this.props.account}
                config={this.props.config} />
            </div>
          </div>
        </aside>
      );
    }
  }

  loading() {
    if (this.props.loading) {
      return (
        <div className="content-main">
          <div className="card panel-themed">
            <div className="card-body">
              <Spinner
                centered
                size={'medium'} />
            </div>
          </div>
        </div>
      );
    }
  }

  content() {
    if (!this.props.loading) {
      return (
        <div className="content-main">
          <div className="card panel-themed">
            <div className="card-body">
              {this.paymentForm()}
            </div>
          </div>
        </div>
      );
    }
  }

  heading() {
    const action = this.props.payment.id ? 'edit_payment' : 'make_payment';
    return (
      <div className="content-main">
        <h1 className="section-heading">
          {this.props.i18n.portal.payer.payment[action]}
        </h1>
      </div>
    );
  }

  render() {
    return (
      <div id="make-payment">
        {this.heading()}
        {this.loading()}
        <div className="row">
          {this.content()}
          {this.sidebar()}
        </div>
      </div>
    );
  }
}

AccountPaymentScreen.propTypes = {
  clearSavedPayment: PropTypes.func,
  clearPaymentErrors: PropTypes.func,
  accounts: PropTypes.object,
  params: PropTypes.object,
  payments: PropTypes.object,
  payMethods: PropTypes.array,
  savePayMethod: PropTypes.func,
  payMethodErrors: PropTypes.array,
  savedPayMethod: PropTypes.func,
  clearSavePayMethodErrors: PropTypes.func,
  savedPayment: PropTypes.object,
  setPayMethodErrors: PropTypes.func,
  abilities: PropTypes.object,
  savePayment: PropTypes.func,
  clearSavedPayMethod: PropTypes.func,
  loading: PropTypes.bool,
  i18n: PropTypes.object,
  config: PropTypes.object,
  content: PropTypes.object,
  fetchPayMethods: PropTypes.func,
  fetchStagedPayment: PropTypes.func,
  errors: PropTypes.array,
  clearErrors: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
  account: PropTypes.object,
  payment: PropTypes.object.isRequired,
  resetScreen: PropTypes.func.isRequired,
  setPayment: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  submitting: PropTypes.bool
};
