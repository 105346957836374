import * as types from '../types';
import initialState from './initialState';

export default function abilitiesReducer(state = initialState.abilities, action) {
  switch (action.type) {
    case types.FETCH_CONFIG_FULFILLED: {
      const currentUser = action.payload.data.current_user;
      const abilities = currentUser ? currentUser.portal_user.abilities : {};
      return abilities;
    }
    case types.LOGIN_FULFILLED: {
      return action.payload.data.portal_user.abilities;
    }
    case types.CREATE_USER_FULFILLED: {
      return action.payload.data.portal_user.abilities;
    }
    case types.LOGOUT_FULFILLED: {
      return {};
    }
    case types.SESSION_TIMEOUT_REJECTED: {
      return {};
    }
    default:
      return state;
  }
}
