import * as types from '../../types';
import PaymentAPI from '../../../api/paymentAPI';
import OneTimePaymentAPI from '../../../api/oneTimePaymentAPI';
import Confirmation from '../../../api/confirmation';

export function fetchStagedPayment(params) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_STAGED_PAYMENT,
      payload: PaymentAPI.fetchStaged(params)
    });
  };
}

export function fetchPayment(id, params) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_PAYMENT,
      payload: PaymentAPI.fetch(id, params)
    });
  };
}

export function newOneTimePayment(account) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_STAGED_PAYMENT,
      payload: OneTimePaymentAPI.new(account)
    });
  };
}

export function updatePayment(payment) {
  return function(dispatch) {
    dispatch({
      type: types.UPDATE_PAYMENT,
      payload: PaymentAPI.updatePayment(payment)
    }).catch((error) => {});
  };
}

export function confirmPayment(payment) {
  return function(dispatch) {
    dispatch({
      type: types.CONFIRM_PAYMENT,
      payload: Confirmation.create(payment)
    }).catch((error) => {});
  };
}
