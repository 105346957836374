import React from 'react';
import { PropTypes } from 'prop-types';
import PayMethodsTable from '../../components/common/PayMethodsTable';
import UsersTable from '../../components/common/UsersTable';
import Button from '../../components/common/Button';
import TabSet from '../../components/common/TabSet';
import Tab from '../../components/common/Tab';
import Modal from '../../components/common/Modal';
import PayMethodModal from '../../components/common/PayMethodModal';
import PayMethodAssignmentModal from '../../components/common/PayMethodAssignmentModal';

class SettingsScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedPayMethod: null,
      selectedUser: null,
      selectedPayMethodAssignment: null,
      card: null,
    };

    this.assignmentEnabled = this.isAssignmentEnabled();
    this.isPayMethodSelected = this.isPayMethodSelected.bind(this);
    this.toggleSelectedPayMethod = this.toggleSelectedPayMethod.bind(this);
    this.isUserSelected = this.isUserSelected.bind(this);
    this.toggleSelectedUser = this.toggleSelectedUser.bind(this);
    this.isPayMethodAssignmentSelected = this.isPayMethodAssignmentSelected.bind(this);
    this.toggleSelectedPayMethodAssignment = this.toggleSelectedPayMethodAssignment.bind(this);
    this.setDefaultPayMethod = this.setDefaultPayMethod.bind(this);
    this.togglePayMethodDeleteModal = this.togglePayMethodDeleteModal.bind(this);
    this.deleteSelectedPayMethod = this.deleteSelectedPayMethod.bind(this);
    this.togglePayMethodModal = this.togglePayMethodModal.bind(this);
    this.closePayMethodModal = this.closePayMethodModal.bind(this);
    this.togglePayMethodAssignmentModal = this.togglePayMethodAssignmentModal.bind(this);
    this.assignPayMethod = this.assignPayMethod.bind(this);
    this.togglePayMethodAssignmentDeleteModal = this.togglePayMethodAssignmentDeleteModal.bind(this);
    this.deleteSelectedPayMethodAssignment = this.deleteSelectedPayMethodAssignment.bind(this);
  }

  componentDidMount() {
    this.props.clearPayMethodAssignments();
    this.props.fetchPayMethods();
    this.fetchPortalUsers();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.savedPayMethod && this.props.savedPayMethod) {
      this.closePayMethodModal();
      this.props.clearSavedPayMethod();
    }
    if (!prevProps.deletedPayMethod && this.props.deletedPayMethod) {
      this.togglePayMethodDeleteModal();
      this.toggleSelectedPayMethod();
      this.props.clearDeletedPayMethod();
    }
  }

  fetchPortalUsers() {
    if (this.assignmentEnabled) {
      this.props.fetchPortalUsers(this.props.currentUser.portal_payer);
    }
  }

  isAssignmentEnabled() {
    const enabled = this.props.config.pay_method_assignment_enabled;
    const hasAbility = this.props.abilities.read_portal_pay_method_assignment;
    return enabled && hasAbility;
  }

  enabledTypesLength() {
    const achEnabled = this.props.config.pay_method_config.ach_enabled;
    const checkEnabled = this.props.config.pay_method_config.check_enabled;
    const creditCardEnabled = this.props.config.pay_method_config.cc_enabled;
    const plastiqEnabled = this.props.config.pay_method_config.plastiq_enabled;
    const enabledTypes = [achEnabled, checkEnabled, creditCardEnabled, plastiqEnabled];
    return enabledTypes.filter((enabled) => enabled).length;
  }

  // --- PAY METHODS
  payMethodsSection() {
    if (this.enabledTypesLength() > 0) {
      return (
        <section className="manage-pay-methods settings-section">
          <h1 className="section-heading">
            {this.props.i18n.portal.payer.settings.manage_pay_methods}
          </h1>
          <div className="card panel-themed">
            <div className="card-body">
              <PayMethodsTable
                selectable
                showDefaultColumn
                results={this.props.payMethods}
                heading={this.props.i18n.portal.payer.settings.pay_methods}
                isRowSelected={this.isPayMethodSelected}
                onRowClick={this.toggleSelectedPayMethod}
                actions={this.payMethodActions()}
                showActions={this.props.abilities.manage_pay_method}
                i18n={this.props.i18n}
                content={this.props.content}
                config={this.props.config} />
            </div>
          </div>
        </section>
      );
    }
  }

  isPayMethodSelected(payMethod) {
    if (payMethod && this.state.selectedPayMethod) {
      return payMethod.id == this.state.selectedPayMethod.id;
    }
  }

  payMethodActions() {
    return (
      <div>
        {this.addPayMethodButton()}{' '}
        {this.removePayMethodButton()}{' '}
        {this.defaultPayMethodButton()}
      </div>
    );
  }

  addPayMethodButton() {
    return (
      <Button icon="plus-circle" onClick={this.togglePayMethodModal}>
        {this.props.i18n.common.add}
      </Button>
    );
  }

  removePayMethodButton() {
    if (this.state.selectedPayMethod) {
      return (
        <Button icon="minus-circle" onClick={this.togglePayMethodDeleteModal}>
          {this.props.i18n.common.remove}
        </Button>
      );
    }
  }

  defaultPayMethodButton() {
    if (this.state.selectedPayMethod && !this.state.selectedPayMethod.default) {
      return (
        <Button icon="check-circle" onClick={this.setDefaultPayMethod}>
          {this.props.i18n.common.default}
        </Button>
      );
    }
  }

  setDefaultPayMethod() {
    this.props.savePayMethod(this.state.selectedPayMethod, {
      default: true
    });
  }

  toggleSelectedPayMethod(payMethod) {
    if (this.isPayMethodSelected(payMethod)) {
      this.setState({ selectedPayMethod: null });
    }
    else {
      this.setState({ selectedPayMethod: payMethod });
    }
  }

  payMethodDeleteModal() {
    return (
      <Modal
        showClose
        showConfirm
        isOpen={this.state.isPayMethodDeleteModalOpen}
        toggle={this.togglePayMethodDeleteModal}
        className="confirm-delete-modal"
        title={this.props.i18n.portal.modals.confirm_remove_header}
        message={this.props.i18n.portal.modals.confirm_remove_message}
        closeLabel={this.props.i18n.common.cancel}
        confirmLabel={this.props.i18n.common.remove}
        confirmClassName="danger"
        onConfirm={this.deleteSelectedPayMethod} />
    );
  }

  togglePayMethodDeleteModal() {
    this.setState({
      isPayMethodDeleteModalOpen: !this.state.isPayMethodDeleteModalOpen
    });
  }

  deleteSelectedPayMethod() {
    this.props.deletePayMethod(this.state.selectedPayMethod);
  }

  payMethodModal() {
    const ccEnabled = this.props.config.pay_method_config.cc_enabled;
    const canManageCreditCards = this.props.abilities.manage_cc_pay_method;
    return (
      <PayMethodModal
        isOpen={this.state.isPayMethodModalOpen}
        toggle={this.togglePayMethodModal}
        onSubmit={this.props.savePayMethod}
        errors={this.props.errors.pay_method}
        clearErrors={this.props.clearSavePayMethodErrors}
        onError={this.props.setPayMethodErrors}
        i18n={this.props.i18n}
        config={this.props.config}
        content={this.props.content}
        abilities={this.props.abilities}
        plastiqEnabled={this.props.config.pay_method_config.plastiq_enabled}
        creditCardEnabled={ccEnabled && canManageCreditCards}
        createStripeIntent={this.props.createStripeIntent}
        stripeIntent={this.props.config.stripeIntent} />
    );
  }

  togglePayMethodModal() {
    this.props.clearSavePayMethodErrors();
    this.setState({
      isPayMethodModalOpen: !this.state.isPayMethodModalOpen
    });
  }

  closePayMethodModal() {
    this.setState({ isPayMethodModalOpen: false });
  }
  // ---

  // --- USERS
  usersSection() {
    if (this.assignmentEnabled) {
      return (
        <section className="manage-users settings-section">
          <h1 className="section-heading">
            {this.props.i18n.portal.payer.settings.manage_users}
          </h1>
          <div className="card panel-themed">
            <div className="card-body">
              {this.usersTable()}
              {this.userTabs()}
              {this.payMethodAssignmentsTable()}
            </div>
          </div>
        </section>
      );
    }
  }

  usersTable() {
    return (
      <UsersTable
        selectable
        results={this.props.users}
        heading={this.props.i18n.portal.payer.settings.users}
        isRowSelected={this.isUserSelected}
        onRowClick={this.toggleSelectedUser}
        i18n={this.props.i18n}
        content={this.props.content}
        config={this.props.config} />
    );
  }

  userTabs() {
    if (this.state.selectedUser) {
      return (
        <TabSet className="user-tabs">
          <Tab show active
            label={this.props.i18n.portal.payer.settings.pay_methods}
            onSelect={this.transactionsTabSelected} />
        </TabSet>
      );
    }
  }

  isUserSelected(user) {
    if (this.state.selectedUser) {
      return user.id == this.state.selectedUser.id;
    }
  }

  toggleSelectedUser(user) {
    if (this.state.selectedUser && this.state.selectedUser.id == user.id) {
      this.setState({ selectedUser: null });
      this.props.clearPayMethodAssignments();
    }
    else {
      this.setState({ selectedUser: user, selectedPayMethodAssignment: null });
      this.props.fetchPayMethodAssignments(user);
    }
  }
  // ---

  // --- PAY METHOD ASSIGNMENTS
  payMethodAssignmentsTable() {
    if (this.state.selectedUser) {
      return (
        <PayMethodsTable
          selectable
          results={this.filteredPayMethodAssignments(this.props.payMethodAssignments)}
          isRowSelected={this.isPayMethodAssignmentSelected}
          onRowClick={this.toggleSelectedPayMethodAssignment}
          actions={this.payMethodAssignmentActions()}
          actionsAlign="left"
          showActions={this.props.abilities.manage_portal_pay_method_assignment}
          i18n={this.props.i18n}
          content={this.props.content}
          config={this.props.config} />
      );
    }
  }

  filteredPayMethodAssignments(results) {
    if (results) {
      return results.filter((payMethodAssignment) => {
        return this.props.payMethods.find((payMethod) => {
          return payMethod.id == payMethodAssignment.id;
        });
      });
    }
  }

  isPayMethodAssignmentSelected(payMethodAssignment) {
    if (payMethodAssignment && this.state.selectedPayMethodAssignment) {
      return payMethodAssignment.id == this.state.selectedPayMethodAssignment.id;
    }
  }

  toggleSelectedPayMethodAssignment(payMethodAssignment) {
    if (this.isPayMethodAssignmentSelected(payMethodAssignment)) {
      this.setState({ selectedPayMethodAssignment: null });
    }
    else {
      this.setState({ selectedPayMethodAssignment: payMethodAssignment });
    }
  }

  payMethodAssignmentActions() {
    return (
      <div>
        {this.addPayMethodAssignmentButton()}{' '}
        {this.removePayMethodAssignmentButton()}
      </div>
    );
  }

  addPayMethodAssignmentButton() {
    return (
      <Button icon="plus-circle" onClick={this.togglePayMethodAssignmentModal}>
        {this.props.i18n.common.add}
      </Button>
    );
  }

  payMethodAssignmentModal() {
    return (
      <PayMethodAssignmentModal
        isOpen={this.state.isPayMethodAssignmentModalOpen}
        toggle={this.togglePayMethodAssignmentModal}
        payMethods={this.getAssignablePayMethods()}
        onPayMethodSelect={this.assignPayMethod}
        i18n={this.props.i18n}
        config={this.props.config}
        content={this.props.content} />
    );
  }

  getAssignablePayMethods() {
    if (this.props.payMethods && Array.isArray(this.props.payMethodAssignments)) {
      return this.props.payMethods.filter((payMethod) => {
        return !this.props.payMethodAssignments.find((assignedPayMethod) => {
          return assignedPayMethod.id == payMethod.id;
        });
      });
    }
  }

  assignPayMethod(payMethod) {
    this.props.createPayMethodAssignment(this.state.selectedUser, payMethod);
    this.togglePayMethodAssignmentModal();
  }

  togglePayMethodAssignmentModal() {
    this.setState({
      isPayMethodAssignmentModalOpen: !this.state.isPayMethodAssignmentModalOpen
    });
  }

  removePayMethodAssignmentButton() {
    const selectedPayMethod = this.state.selectedPayMethodAssignment;
    if (selectedPayMethod && this.canRemovePayMethod(selectedPayMethod)) {
      return (
        <Button
          icon="minus-circle"
          onClick={this.togglePayMethodAssignmentDeleteModal}
        >
          {this.props.i18n.common.remove}
        </Button>
      );
    }
  }

  canRemovePayMethod(payMethod) {
    const hasAbility = this.props.abilities.manage_portal_pay_method_assignment;
    const assignedPayMethod = this.props.payMethods.find((p) => {
      return p.id == payMethod.id;
    });
    return hasAbility && assignedPayMethod;
  }

  payMethodAssignmentDeleteModal() {
    return (
      <Modal
        showClose
        showConfirm
        isOpen={this.state.isPayMethodAssignmentDeleteModalOpen}
        toggle={this.togglePayMethodAssignmentDeleteModal}
        className="confirm-delete-modal"
        title={this.props.i18n.portal.modals.confirm_remove_header}
        message={this.props.i18n.portal.modals.confirm_remove_message}
        closeLabel={this.props.i18n.common.cancel}
        confirmLabel={this.props.i18n.common.remove}
        confirmClassName="danger"
        onConfirm={this.deleteSelectedPayMethodAssignment} />
    );
  }

  togglePayMethodAssignmentDeleteModal() {
    this.setState({
      isPayMethodAssignmentDeleteModalOpen: !this.state.isPayMethodAssignmentDeleteModalOpen
    });
  }

  deleteSelectedPayMethodAssignment() {
    const portalUser = this.state.selectedUser;
    const payMethod = this.state.selectedPayMethodAssignment;
    this.props.deletePayMethodAssignment(portalUser, payMethod);
    this.toggleSelectedPayMethodAssignment();
    this.togglePayMethodAssignmentDeleteModal();
  }
  // ---

  render() {
    return (
      <div id="admin-settings">
        <div className="content-main">
          {this.payMethodsSection()}
          {this.usersSection()}
        </div>
        {this.payMethodModal()}
        {this.payMethodDeleteModal()}
        {this.payMethodAssignmentModal()}
        {this.payMethodAssignmentDeleteModal()}
      </div>
    );
  }
}

SettingsScreen.propTypes = {
  savedPayMethod: PropTypes.object,
  deletedPayMethod: PropTypes.array,
  columns: PropTypes.array,
  showPager: PropTypes.bool,
  showButtons: PropTypes.bool,
  filterPlaceholder: PropTypes.string,
  createStripeIntent: PropTypes.func,
  createPayMethodAssignment: PropTypes.func,
  clearPayMethodAssignments: PropTypes.func,
  fetchPayMethodAssignments: PropTypes.func,
  deletePayMethodAssignment: PropTypes.func,
  fetchPayMethods: PropTypes.func,
  clearSavedPayMethod: PropTypes.func,
  savePayMethod: PropTypes.func,
  deletePayMethod: PropTypes.func,
  clearDeletedPayMethod: PropTypes.func,
  clearSavePayMethodErrors: PropTypes.func,
  setPayMethodErrors: PropTypes.func,
  fetchPortalUsers: PropTypes.func,
  currentUser: PropTypes.object,
  config: PropTypes.object,
  abilities: PropTypes.object,
  i18n: PropTypes.object,
  payMethods: PropTypes.array,
  errors: PropTypes.object,
  payMethodAssignments: PropTypes.array,
  users: PropTypes.array,
  content: PropTypes.object,
  toastManager: PropTypes.object.isRequired,
};

export default SettingsScreen;
