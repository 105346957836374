import { http } from 'shared/api';

export default class Subscription {
  static create(noticeRule) {
    return http.post(`notice_subscriptions`, {
      notice_subscription: {
        notice_rule_id: noticeRule.id,
      }
    });
  }

  static destroy(subscription) {
    return http.delete(`notice_subscriptions/${subscription.id}`);
  }
}