import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { hashHistory } from 'react-router';

import FormErrors from 'shared/components/FormErrors';
import Icon from 'shared/components/Icon';

import Button from '../../components/common/Button';
import FindAccountForm from '../../components/account/FindAccountForm';
import Spinner from '../../components/common/Spinner';
import UserDetails from '../../components/common/UserDetails';
import UserForm from '../../components/common/UserForm';

export default class RegistrationScreen extends Component {
  constructor(props) {
    super(props);
    this.submitAccount = this.submitAccount.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.userDetails = this.userDetails.bind(this);
  }

  componentWillMount() {
    this.authorize();
    this.props.resetScreen();
  }

  authorize() {
    if (!this.props.config.user_registration_enabled) {
      hashHistory.push('/');
    }
  }

  formErrors() {
    return (
      <FormErrors
        errors={this.props.errors}
        dismiss={this.props.clearErrors} />
    );
  }

  accountForm() {
    if (this.isCurrentStep('findAccount')) {
      return (
        <section>
          <h1 className="sr-only">
            {this.props.i18n.portal.ada_headers.account_lookup_form}
          </h1>
          <FindAccountForm
            showNotice
            onSubmit={this.submitAccount}
            i18n={this.props.i18n}
            content={this.props.content}
            config={this.props.config}
            fields={this.props.config.feature.portal_account_form_fields} />
        </section>
      );
    }
  }

  submitAccount(account) {
    this.props.clearErrors();
    if (this.props.registration) {
      this.props.createRegistrationAccount(this.props.registration, account);
    }
    else {
      this.props.createRegistration(account);
    }
  }

  accounts() {
    if (this.props.accounts.length) {
      return (
        <div>
          {this.props.accounts.map(this.userDetails)}
        </div>
      );
    }
  }

  actions() {
    if (this.props.accounts.length) {
      return (
        <div className="row my-4">
          <div className="col-sm-8 offset-sm-2 btn-group btn-group-toggle">
            {this.actionButton('findAccount', this.props.i18n.portal.sign_up.add_account, 'plus-circle')}
            {this.actionButton('register', this.props.i18n.common.register)}
          </div>
        </div>
      );
    }
  }

  actionButton(step, label, icon) {
    const classes = ['btn', 'btn-secondary'];
    if (this.isCurrentStep(step)) { classes.push('active'); }

    return (
      <label className={classes.join(' ')} style={{ 'flex-basis': '0' }}>
        <input
          type="radio"
          name="currentStep"
          value={step}
          autoComplete="off"
          onChange={() => this.props.setCurrentStep(step)}
        />
        {icon ? <><Icon type={icon} />{' '}</> : null}
        {label}
      </label>
    );
  }

  isCurrentStep(step) {
    return this.props.currentStep == step;
  }

  userDetails(account) {
    return (
      <UserDetails
        i18n={this.props.i18n}
        content={this.props.content}
        key={account.id}
        account={account}
        fields={['payers.name', 'receivable_accounts.external_key']} />
    );
  }

  userForm() {
    if (this.isCurrentStep('register')) {
      return (
        <section>
          <h1 className="sr-only">
            User Credentials Form
          </h1>
          <UserForm
            onSubmit={this.submitUser}
            i18n={this.props.i18n}
            content={this.props.content} />
        </section>
      );
    }
  }

  submitUser(user) {
    this.props.clearErrors();
    this.props.createUser(this.props.registration, user);
  }

  loading() {
    if (this.props.loading) {
      return (
        <Spinner centered size="medium" />
      );
    }
  }

  content() {
    if (!this.props.loading) {
      return (
        <div>
          {this.accounts()}
          {this.actions()}
          {this.accountForm()}
          {this.userForm()}
        </div>
      );
    }
  }

  render() {
    return (
      <div id="registration-screen">
        {this.formErrors()}
        <div className="card panel-landing">
          <div className="card-body">
            <h1>
              {this.props.i18n.portal.sign_up.register_account}
            </h1>
            {this.loading()}
            {this.content()}
          </div>
        </div>
      </div>
    );
  }
}

RegistrationScreen.propTypes = {
  errors: PropTypes.array,
  content: PropTypes.object,
  config: PropTypes.object,
  i18n: PropTypes.object,
  registration: PropTypes.object,
  resetScreen: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  clearErrors: PropTypes.func.isRequired,
  createRegistration: PropTypes.func.isRequired,
  createRegistrationAccount: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  newAccount: PropTypes.func.isRequired
};
