import * as types from '../types';
import initialState from './initialState';

export default function createdRecurrenceAccountReducer(state = initialState.createdRecurrenceAccount, action) {
  switch (action.type) {
    case types.CREATE_RECURRENCE_ACCOUNT_FULFILLED: {
      return action.payload.data;
    }
    case types.CLEAR_CREATED_RECURRENCE_ACCOUNT: {
      return null;
    }
    default:
      return state;
  }
}
