import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';

// Screens
import { default as registrationScreen } from './screens/registration/reducer';
import { default as dashboardScreen } from './screens/dashboard/reducer';
import { default as oneTimePaymentScreen } from './screens/one_time_payment/reducer';
import { default as accountPaymentScreen } from './screens/account_payment/reducer';
import { default as billPaymentScreen } from './screens/bill_payment/reducer';
import { default as accountScreen } from './screens/account/reducer';

//
// TODO: Move to appropriate screen reducer
//
import app from './app/reducer';
import loading from './reducers/loadingReducer';
import sessionTimeout from './reducers/sessionTimeoutReducer';
import config from './reducers/configReducer';
import content from './reducers/contentReducer';
import i18n from './reducers/i18nReducer';
import currentUser from './reducers/currentUserReducer';
import abilities from './reducers/abilitiesReducer';
import resetPassword from './reducers/resetPasswordReducer';
import errors from './reducers/errorsReducer';
import bills from './reducers/billsReducer';
import selectedBills from './reducers/selectedBillsReducer';
import accounts from './reducers/accountsReducer';
import receivableAccount from './reducers/receivableAccountReducer';
import createdUser from './reducers/createdUserReducer';
import payments from './reducers/paymentsReducer';
import payment from './reducers/paymentReducer';
import savedPayment from './reducers/savedPaymentReducer';
import deletedPayment from './reducers/deletedPaymentReducer';
import payMethods from './reducers/payMethodsReducer';
import billRecurrences from './reducers/billRecurrencesReducer';
import savedBillRecurrence from './reducers/savedBillRecurrenceReducer';
import deletedBillRecurrence from './reducers/deletedBillRecurrenceReducer';
import recurrenceAccounts from './reducers/recurrenceAccountsReducer';
import createdRecurrenceAccount from './reducers/createdRecurrenceAccountReducer';
import deletedRecurrenceAccount from './reducers/deletedRecurrenceAccountReducer';
import portalAccounts from './reducers/portalAccountsReducer';
import createdAccountRecurrence from './reducers/createdAccountRecurrenceReducer';
import deletedAccountRecurrence from './reducers/deletedAccountRecurrenceReducer';
import savedPayMethod from './reducers/savedPayMethodReducer';
import deletedPayMethod from './reducers/deletedPayMethodReducer';
import users from './reducers/usersReducer';
import payMethodAssignments from './reducers/payMethodAssignmentsReducer';
import billDocuments from './reducers/billDocumentsReducer';
import externalBills from './reducers/externalBillsReducer';
import externalPayments from './reducers/externalPaymentsReducer';
import externalPayment from './reducers/externalPaymentReducer';
import createPortalAgreement from './reducers/createPortalAgreementReducer';

const rootReducer = combineReducers({
  screens: combineReducers({
    registration: registrationScreen,
    dashboard: dashboardScreen,
    billPayment: billPaymentScreen,
    accountPayment: accountPaymentScreen,
    oneTimePayment: oneTimePaymentScreen,
    account: accountScreen
  }),

  //
  // TODO: Move to appropriate screen reducer
  //
  app,
  loading,
  config,
  content,
  i18n,
  currentUser,
  abilities,
  errors,
  bills,
  selectedBills,
  accounts,
  sessionTimeout,
  routing,
  resetPassword,
  receivableAccount,
  createdUser,
  createPortalAgreement,
  payments,
  payment,
  savedPayment,
  deletedPayment,
  payMethods,
  billRecurrences,
  savedBillRecurrence,
  deletedBillRecurrence,
  recurrenceAccounts,
  createdRecurrenceAccount,
  deletedRecurrenceAccount,
  portalAccounts,
  createdAccountRecurrence,
  deletedAccountRecurrence,
  savedPayMethod,
  deletedPayMethod,
  payMethodAssignments,
  users,
  billDocuments,
  externalBills,
  externalPayments,
  externalPayment
});

export default rootReducer;
