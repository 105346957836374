import * as types from '../types';
import initialState from './initialState';

export default function contentReducer(state = initialState.content, action) {
  switch (action.type) {
    case types.FETCH_CONFIG_FULFILLED:
      return { ...state, ...action.payload.data.content };

    default:
      return state;
  }
}
