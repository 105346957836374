/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Route, IndexRoute } from 'react-router';
import App from './components/App';
import PayerLayout from './components/payer/PayerLayout';

import RegistrationScreen from './screens/registration';
import DashboardScreen from './screens/dashboard';
import OneTimePaymentScreen from './screens/one_time_payment';
import AccountPaymentScreen from './screens/account_payment';
import BillPaymentScreen from './screens/bill_payment';
import AccountScreen from './screens/account';
import SettingsScreen from './screens/settings';

//
// TODO: Refactor pages to screens with dedicated container and state
//
import LandingPage from './components/landing/LandingPage';
import LoginPage from './components/login/LoginPage';
import ForgotPasswordPage from './components/login/ForgotPasswordPage';
import PasswordResetPage from './components/login/PasswordResetPage';
import HelpPage from './components/payer/HelpPage';
import BillRecurrencePage from './components/payer/bill_recurrence/BillRecurrencePage';

export default (
  <Route path="/" component={App}>
    <IndexRoute component={LandingPage} landing />
    <Route path="login" component={LoginPage} landing navbar />
    <Route path="forgot_password" component={ForgotPasswordPage} landing navbar />
    <Route path="reset_password" component={PasswordResetPage} landing navbar />
    <Route path="signup" component={RegistrationScreen} landing navbar />
    <Route path="otp(/account)" component={OneTimePaymentScreen} landing navbar />
    <Route path="payer" component={PayerLayout}>
      <IndexRoute component={DashboardScreen} />
      <Route path="settings" component={SettingsScreen} />
      <Route path="account" component={AccountScreen} />
      <Route path="help" component={HelpPage} />
      <Route path="autopay" component={BillRecurrencePage} />
      <Route path="accounts/:accountID/payment(/:paymentID)" component={AccountPaymentScreen} />
      <Route path="bill_payment(/:paymentID)" component={BillPaymentScreen} />
    </Route>
    {/* %Route( path="*" component={NoMatch} } */}
  </Route>
);
