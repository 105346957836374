import * as types from '../types';
import initialState from './initialState';

export default function i18nReducer(state = initialState.i18n, action) {
  switch (action.type) {
    case types.FETCH_CONFIG_FULFILLED:
      return { ...state, ...action.payload.data.i18n };

    default:
      return state;
  }
}
