import * as types from '../types';
import initialState from './initialState';

export default function recurrenceAccountsReducer(state = initialState.recurrenceAccounts, action) {
  switch (action.type) {
    case types.FETCH_RECURRENCE_ACCOUNTS_FULFILLED:
      return { ...state, data: action.payload.data };

    case types.CLEAR_RECURRENCE_ACCOUNTS:
      return {};

    default:
      return state;
  }
}
