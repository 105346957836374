import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { hashHistory } from 'react-router';
import FormErrors from 'shared/components/FormErrors';
import FindAccountForm from '../../components/account/FindAccountForm';
import PaymentForm from '../../components/common/PaymentForm';
import Button from '../../components/common/Button';
import OneTimePaymentAPI from '../../api/oneTimePaymentAPI';
import Link from '../../components/common/Link';
import NoticePopover from '../../components/common/NoticePopover';
import Spinner from '../../components/common/Spinner';

export default class OneTimePaymentScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.findAccount = this.findAccount.bind(this);
    this.submitPayment = this.submitPayment.bind(this);
    this.printPayment = this.printPayment.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
  }

  componentWillMount() {
    this.checkOTPEnabled();
    this.props.resetScreen();
    this.props.clearAccounts();
    this.props.clearSavedPayment();
    this.props.clearPaymentErrors();
  }

  componentWillReceiveProps(nextProps) {
    this.handleAccountsChange(nextProps);
    this.handleAccountChange(nextProps);
  }

  checkOTPEnabled() {
    if (!this.props.config.one_time_payment_enabled) {
      this.goToLandingPage();
    }
  }

  handleAccountsChange(nextProps) {
    if (!this.props.accounts && nextProps.accounts) {
      const accounts = nextProps.accounts;
      if (accounts.length > 1) {
        this.props.setCurrentStep('select_account');
      }
      else if (accounts.length == 1) {
        this.props.setAccount(accounts[0]);
      }
      else if (nextProps.accounts.length == 0) {
        this.props.setPaymentErrors(this.props.content.account_not_found_validation);
        this.props.clearAccounts();
      }
    }
  }

  handleAccountChange(nextProps) {
    if (!this.props.account && nextProps.account) {
      this.props.newOneTimePayment(nextProps.account);
    }
  }

  handlePaymentChange(payment) {
    this.props.setPayment(payment);
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  paymentFooterNotice() {
    if (this.props.content.payment_footer_notice && this.props.account) {
      return (
        <span>
          {this.paymentFooterNoticeButton()}
          {this.paymentFooterNoticeText()}
        </span>
      );
    }
  }

  paymentFooterNoticeButton() {
    return (
      <Link className="btn-help otp-notice" onClick={this.togglePopover}>
        <span id="PaymentFooterNoticePopover">
          <i className="fa icon-help" />
        </span>
      </Link>
    );
  }

  paymentFooterNoticeText() {
    return (
      <NoticePopover
        placement={"top"}
        isOpen={this.state.popoverOpen}
        target={"PaymentFooterNoticePopover"}
        toggle={this.togglePopover}
        source={this.props.content.payment_footer_notice}
        className="payment-footer-notice top"
        containerTagName="span"
      />
    );
  }

  accountForm() {
    if (this.props.currentStep == 'find_account') {
      return (
        <FindAccountForm
          content={this.props.content}
          onSubmit={this.findAccount}
          i18n={this.props.i18n}
          config={this.props.config}
          fields={this.props.config.feature.portal_account_form_fields} />
      );
    }
  }

  accountsList() {
    if (this.props.currentStep == 'select_account') {
      return (
        <div>
          <p className="lead">
            {this.props.i18n.portal.otp.select_account}
          </p>
          {this.props.accounts.map((account) => this.accountLink(account))}
        </div>
      );
    }
  }

  accountLink(account) {
    const setAccount = this.props.setAccount.bind(this, account);
    return (
      <Button block key={account.id} onClick={setAccount} size="lg">
        {account.account_label}
      </Button>
    );
  }

  findAccount(accountParams) {
    this.props.clearPaymentErrors();
    this.props.fetchAccounts(null, accountParams);
  }

  formErrors() {
    return (
      <FormErrors
        errors={this.props.errors}
        dismiss={this.props.clearPaymentErrors} />
    );
  }

  paymentForm() {
    if (this.props.currentStep == 'details') {
      return (
        <PaymentForm
          oneTimePayment
          showSteps
          showAmountField
          showUserDetails
          newPayMethodMode
          payment={this.props.payment}
          account={this.props.account}
          payMethods={[]}
          creditCardEnabled={this.props.config.pay_method_config.cc_enabled}
          onChange={this.handlePaymentChange}
          onSubmit={this.submitPayment}
          savedPayment={this.props.savedPayment}
          onDone={this.goToLandingPage}
          onPrint={this.printPayment}
          showErrors={false}
          showNicknameField={false}
          commentRequired={this.props.config.otp_comment_required}
          errors={this.props.errors}
          payMethodErrors={this.props.payMethodErrors}
          onPayMethodError={this.props.setPaymentErrors}
          clearPayMethodErrors={this.props.clearPaymentErrors}
          clearErrors={this.props.clearPaymentErrors}
          i18n={this.props.i18n}
          config={this.props.config}
          content={this.props.content}
          abilities={this.props.abilities}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          labelClass="col-md-4"
          accountDetailsClass="card info-panel"
          submitting={this.props.submitting}
          createStripeIntent={this.props.createStripeIntent}
          stripeIntent={this.props.config.stripeIntent}
          plastiqEnabled={this.props.config.pay_method_config.plastiq_enabled}
        />
      );
    }
  }

  submitPayment(payment, opts = {}) {
    this.props.createOneTimePayment(payment, opts.newPayMethod, this.props.account);
  }

  printPayment() {
    window.location = OneTimePaymentAPI.exportUrl(this.props.savedPayment, 'pdf');
  }

  goToLandingPage() {
    hashHistory.push('/');
  }

  header() {
    return (
      <span>
        <h1>{this.props.i18n.portal.otp.header}</h1>
        {this.paymentFooterNotice()}
      </span>
    );
  }

  content() {
    if (!this.props.loading) {
      return (
        <div>
          {this.accountForm()}
          {this.accountsList()}
          {this.paymentForm()}
        </div>
      );
    }
  }

  loading() {
    if (this.props.loading) {
      return (
        <Spinner centered size="medium" />
      );
    }
  }

  render() {
    return (
      <div id="one-time-payment">
        {this.formErrors()}
        <div className="card panel-landing">
          <div className="card-body">
            {this.header()}
            {this.loading()}
            {this.content()}
          </div>
        </div>
      </div>
    );
  }
}

OneTimePaymentScreen.propTypes = {
  savedPayment: PropTypes.object,
  createOneTimePayment: PropTypes.func,
  clearSavedPayment: PropTypes.func,
  setPaymentErrors: PropTypes.func,
  clearPaymentErrors: PropTypes.func,
  clearAccounts: PropTypes.func,
  fetchAccounts: PropTypes.func,
  accounts: PropTypes.array,
  account: PropTypes.object,
  payment: PropTypes.object,
  abilities: PropTypes.object,
  payMethodErrors: PropTypes.array,
  errors: PropTypes.array,
  content: PropTypes.object,
  config: PropTypes.object,
  i18n: PropTypes.object,
  resetScreen: PropTypes.func.isRequired,
  setPayment: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
  newOneTimePayment: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  stripeIntent: PropTypes.string,
  createStripeIntent: PropTypes.func,
};
