import * as types from '../types';
import initialState from './initialState';

export default function configReducer(state = initialState.config, action) {
  switch (action.type) {
    case types.FETCH_CONFIG_FULFILLED: {
      let config = { ...action.payload.data };
      const user = config.current_user;
      delete config.current_user;
      delete config.content;
      delete config.i18n;
      return {
        ...state,
        ...config,
        pay_to_account_enabled: isPayToAccountEnabled(user),
        isLoaded: true
      };
    }
    case types.FETCH_CONFIG_PENDING: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case types.LOGIN_FULFILLED: {
      return {
        ...state,
        pay_to_account_enabled: isPayToAccountEnabled(action.payload.data)
      };
    }
    case types.CREATE_STRIPE_INTENT_FULFILLED: {
      return {
        ...state,
        stripeIntent: action.payload.data.stripe_intent
      };
    }
    default:
      return state;
  }
}

function isPayToAccountEnabled(user) {
  return user ? user.portal_user.pay_to_account_enabled : false;
}
