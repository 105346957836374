import * as types from '../types';
import initialState from './initialState';

export default function accountsReducer(state = initialState.accounts, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNTS_FULFILLED:
      return { data: action.payload.data };

    case types.CLEAR_ACCOUNTS:
      return {};

    default:
      return state;
  }
}
