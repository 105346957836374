import * as types from '../types';
import initialState from './initialState';

export default function receivableAccountReducer(state = initialState.receivableAccount, action) {
  switch (action.type) {
    case types.FETCH_RECEIVABLE_ACCOUNT_FULFILLED:
      return action.payload.data;

    default:
      return state;
  }
}
