import * as types from '../../types';
import moment from 'moment';

export const initialState = {
  loading: true,
  currentStep: 'details',
  account: null,
  payment: {},
  minDate: null,
  maxDate: null
};

export default function(state = initialState, { type, payload, meta }) {
  switch (type) {
    case types.RESET_SCREEN: {
      return initialState;
    }
    case types.FETCH_STAGED_PAYMENT_FULFILLED: {
      const data = payload.data;
      const payment = data.portal_payment;
      const minDate = data.min_date ? moment(data.min_date) : null;
      const maxDate = data.max_date ? moment(data.max_date) : null;
      return {
        ...state,
        loading: false,
        minDate,
        maxDate,
        payment: {
          ...payment,
          ...state.payment
        }
      };
    }
    case types.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: payload
      };
    }
    case types.SET_PAYMENT: {
      return {
        ...state,
        payment: {
          ...state.payment,
          ...payload
        }
      };
    }
    case types.SAVE_PAYMENT_PENDING: {
      return {
        ...state,
        submitting: true
      };
    }
    case types.SAVE_PAYMENT_REJECTED: {
      return {
        ...state,
        submitting: false
      };
    }
    case types.SAVE_PAYMENT_FULFILLED: {
      return {
        ...state,
        submitting: false
      };
    }
    case types.SET_ACCOUNT: {
      return {
        ...state,
        account: payload
      };
    }
    default:
      return state;
  }
}
