import { http } from 'shared/api';

export default class RegistrationAccount {

  static create(registration, account) {
    return http.post(`registrations/${registration.token}/registration_accounts`, {
      account
    });
  }
}
