import * as types from '../types';
import initialState from './initialState';

export default function savedPaymentReducer(state = initialState.savedPayment, action) {
  switch (action.type) {
    case types.SAVE_PAYMENT_FULFILLED: {
      return action.payload.data;
    }
    case types.CLEAR_SAVED_PAYMENT: {
      return null;
    }
    default:
      return state;
  }
}
