import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BillPaymentScreen from './screen';
import * as actionCreators from '../../state/screens/bill_payment/actions';

function mapStateToProps(state) {
  return {
    ...state.screens.billPayment,
    payMethodErrors: state.errors.pay_method,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(BillPaymentScreen);
