import * as types from '../types';
import initialState from './initialState';

export default function billDocumentsReducer(state = initialState.billDocuments, action) {
  switch (action.type) {
    case types.FETCH_BILL_DOCUMENTS_FULFILLED:
      return action.payload.data;
    case types.CLEAR_BILL_DOCUMENTS:
      return null;
    default:
      return state;
  }
}
