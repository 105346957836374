import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardScreen from './screen';
import * as actionCreators from '../../state/screens/dashboard/actions';

function mapStateToProps(state) {
  return {
    unconfirmedPayment: state.screens.dashboard.unconfirmedPayment,
    accountRecurrenceErrors: state.screens.dashboard.accountRecurrenceErrors,
    clearAccountRecurrenceErrors: state.screens.dashboard.clearAccountRecurrenceErrors,
    showWelcome: state.screens.dashboard.showWelcome,
    fetchedPayment: state.screens.dashboard.fetchedPayment,
    fetchingPayment: state.screens.dashboard.fetchingPayment,
    splitsQuery: state.screens.dashboard.splitsQuery,
    splits: state.screens.dashboard.splits,
    splitsData: state.screens.dashboard.splitsData,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(DashboardScreen);
