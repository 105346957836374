import * as types from '../types';
import initialState from './initialState';

export default function deletedRecurrenceAccountReducer(state = initialState.deletedRecurrenceAccount, action) {
  switch (action.type) {
    case types.DELETE_RECURRENCE_ACCOUNT_FULFILLED: {
      return action.payload.data;
    }
    case types.CLEAR_DELETED_RECURRENCE_ACCOUNT: {
      return null;
    }
    default:
      return state;
  }
}
