import * as types from '../../types';

export const initialState = {
  subscriptionLoading: false,
};

export default function(state = initialState, { type, payload, meta }) {
  switch (type) {
    case types.SUBSCRIBE_PENDING: {
      return {
        ...state,
        subscriptionLoading: true
      };
    }
    case types.SUBSCRIBE_FULFILLED: {
      return {
        ...state,
        subscriptionLoading: false
      };
    }
    case types.SUBSCRIBE_REJECTED: {
      return {
        ...state,
        subscriptionLoading: false
      };
    }
    case types.UNSUBSCRIBE_PENDING: {
      return {
        ...state,
        subscriptionLoading: true
      };
    }
    case types.UNSUBSCRIBE_FULFILLED: {
      return {
        ...state,
        subscriptionLoading: false
      };
    }
    case types.UNSUBSCRIBE_REJECTED: {
      return {
        ...state,
        subscriptionLoading: false
      };
    }
    default:
      return state;
  }
}