export default {
  loading: { inProgress: 0 },
  sessionTimeout: null,
  config: { isLoaded: false },
  content: {},
  i18n: {},
  currentUser: null,
  abilities: {},
  errors: {},
  resetPassword: false,
  bills: { overview: {} },
  selectedBills: [],
  accounts: {},
  receivableAccount: false,
  createdUser: null,
  createPortalAgreement: false,
  payments: {},
  payment: null,
  billRecurrences: {},
  savedBillRecurrence: null,
  deletedBillRecurrence: null,
  recurrenceAccounts: {},
  createdRecurrenceAccount: null,
  deletedRecurrenceAccount: null,
  portalAccounts: {},
  createdAccountRecurrence: null,
  deletedAccountRecurrence: null,
  savedPayment: null,
  payMethodAssignments: null,
  users: null,
  deletedPayment: null,
  payMethods: null,
  savedPayMethod: null,
  deletedPayMethod: null,
  billDocuments: null,
  externalBills: {},
  externalPayments: {},
  externalPayment: null,
  showLoadingOverlay: false
};
