import * as types from '../types';
import initialState from './initialState';

export default function accountRecurrencesReducer(state = initialState.createdAccountRecurrence, action) {
  switch (action.type) {
    case types.CREATE_ACCOUNT_RECURRENCE_FULFILLED:
      return action.payload.data;

    case types.DELETE_ACCOUNT_RECURRENCE_FULFILLED:
      return null;

    default:
      return state;
  }
}
