import React from 'react';
import { PropTypes } from 'prop-types';
import AccountPanel from './AccountPanel';
import Spinner from '../../../components/common/Spinner';

class AccountsOverview extends React.Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.createdAccountRecurrence) {
      this.props.fetchAccounts(this.props.currentUser.portal_user);
      this.props.fetchPayments();
      this.props.clearCreatedAccountRecurrence();
    }
    if (nextProps.deletedAccountRecurrence) {
      this.props.fetchAccounts(this.props.currentUser.portal_user);
      this.props.fetchPayments();
      this.props.clearDeletedAccountRecurrence();
    }
  }

  accountPanels() {
    if (this.props.accounts.data && this.props.accounts.data.accounts) {
      return this.props.accounts.data.accounts.map((account, i) =>
        <AccountPanel
          {...this.props}
          account={account}
          key={account.id}
          accountRecurrenceErrors={this.props.accountRecurrenceErrors}
          clearAccountRecurrenceErrors={this.props.clearAccountRecurrenceErrors} />
      );
    }
  }

  loading() {
    if (!this.props.accounts.data) {
      return (
        <div className="card panel-themed">
          <div className="card-body">
            <Spinner centered size="medium" />
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <section className="dash-section accounts-section">
        <h1 className="section-heading">
          {this.props.i18n.portal.dashboard.accounts}
        </h1>
        {this.loading()}
        {this.accountPanels()}
      </section>
    );
  }
}

AccountsOverview.propTypes = {
  accounts: PropTypes.object,
  payMethods: PropTypes.array,
  i18n: PropTypes.object.isRequired,
  labelConfig: PropTypes.object.isRequired,
  fetchPayments: PropTypes.func.isRequired,
  fetchAccounts: PropTypes.func.isRequired,
  clearCreatedAccountRecurrence: PropTypes.func.isRequired,
  clearDeletedAccountRecurrence: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  accountRecurrenceErrors: PropTypes.array,
  clearAccountRecurrenceErrors: PropTypes.func.isRequired
};

export default AccountsOverview;
