import * as types from '../../types';

export const initialState = {
  accountRecurrenceErrors: null,
  showWelcome: true,
  fetchedPayment: null,
  fetchingPayment: false,
  splitsData: {},
  splits: []
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_PAYMENT_PENDING: {
      return {
        ...state,
        fetchingPayment: true
      };
    }
    case types.FETCH_PAYMENT_FULFILLED: {
      return {
        ...state,
        fetchedPayment: payload.data,
        fetchingPayment: false,
        splits: payload.data.portal_splits_data.portal_splits,
        splitsData: payload.data.portal_splits_data
      };
    }
    case types.UNCONFIRM_PAYMENT_FULFILLED: {
      return {
        ...state,
        unconfirmedPayment: payload.data
      };
    }
    case types.CLEAR_UNCONFIRMED_PAYMENT: {
      return {
        ...state,
        unconfirmedPayment: null
      };
    }
    case types.CLEAR_ACCOUNT_RECURRENCE_ERRORS: {
      return {
        ...state,
        accountRecurrenceErrors: null
      };
    }
    case types.HIDE_WELCOME: {
      return {
        ...state,
        showWelcome: false
      };
    }
    case types.FETCH_SPLITS_FULFILLED: {
      const paginated_splits = payload.data.portal_splits;
      let splits = paginated_splits;
      return {
        ...state,
        splits,
        splitsData: payload.data
      };
    }
    case types.CREATE_ACCOUNT_RECURRENCE_REJECTED: {
      return {
        ...state,
        accountRecurrenceErrors: payload.response.data.errors
      };
    }
    default:
      return state;
  }
}
