import * as types from '../types';
import initialState from './initialState';
import bugsnagClient from '../../bugsnag';

export default function currentUserReducer(state = initialState.currentUser, action) {
  switch (action.type) {
    case types.FETCH_CONFIG_FULFILLED: {
      return setCurrentUser(action.payload.data.current_user);
    }
    case types.LOGIN_FULFILLED: {
      return setCurrentUser(action.payload.data);
    }
    case types.CREATE_USER_FULFILLED: {
      return setCurrentUser(action.payload.data);
    }
    case types.LOGOUT_FULFILLED: {
      return setCurrentUser(null);
    }
    case types.SESSION_TIMEOUT_REJECTED: {
      return setCurrentUser(null);
    }
    case types.UPDATE_PASSWORD_FULFILLED: {
      return setCurrentUser(null);
    }
    case types.UPDATE_USER_FULFILLED: {
      return {
        ...action.payload.data,
        updated_successfully: true
      };
    }
    case types.CREATE_PORTAL_AGREEMENT_FULFILLED: {
      return {
        ...state,
        portal_user: {
          ...state.portal_user,
          portal_agreement_accepted: true
        }
      };
    }
    case types.CLEAR_UPDATED_USER_CHECK: {
      return {
        ...state,
        updated_successfully: false
      };
    }
    case types.SUBSCRIBE_FULFILLED: {
      return {
        ...state,
        portal_user: {
          ...state.portal_user,
          notice_subscriptions: action.payload.data
        }
      };
    }
    case types.UNSUBSCRIBE_FULFILLED: {
      return {
        ...state,
        portal_user: {
          ...state.portal_user,
          notice_subscriptions: action.payload.data
        }
      };
    }
    default:
      return state;
  }
}

function setCurrentUser(user) {
  if (bugsnagClient) bugsnagClient.user = user;
  return user;
}
