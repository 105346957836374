import { http } from 'shared/api';

class Confirmation {

  static create(payment) {
    return http.post(`portal_payments/${payment.id}/portal_confirmation`);
  }

  static delete(payment) {
    return http.delete(`portal_payments/${payment.id}/portal_confirmation`);
  }
}

export default Confirmation;
