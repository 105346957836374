import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OneTimePaymentScreen from './screen';
import * as actionCreators from '../../state/screens/one_time_payment/actions';

function mapStateToProps(state) {
  return {
    loading: state.screens.oneTimePayment.loading,
    currentStep: state.screens.oneTimePayment.currentStep,
    accounts: state.screens.oneTimePayment.accounts,
    account: state.screens.oneTimePayment.account,
    payment: state.screens.oneTimePayment.payment,
    errors: state.errors.payment,
    payMethodErrors: state.errors.pay_method,
    minDate: state.screens.oneTimePayment.minDate,
    maxDate: state.screens.oneTimePayment.maxDate,
    submitting: state.screens.oneTimePayment.submitting
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(OneTimePaymentScreen);
