import * as types from '../types';
import initialState from './initialState';

export default function savedPayMethodReducer(state = initialState.savedPayMethod, action) {
  switch (action.type) {
    case types.SAVE_PAY_METHOD_FULFILLED: {
      return action.payload.data;
    }
    case types.CLEAR_SAVED_PAY_METHOD: {
      return null;
    }
    default:
      return state;
  }
}
