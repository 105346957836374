import * as types from '../types';
import initialState from './initialState';

export default function deletedPaymentReducer(state = initialState.deletedPayment, action) {
  switch (action.type) {
    case types.DELETE_PAYMENT_FULFILLED: {
      return action.payload.data;
    }
    case types.CLEAR_DELETED_PAYMENT: {
      return null;
    }
    default:
      return state;
  }
}
