import * as types from '../types';
import initialState from './initialState';

export default function paymentReducer(state = initialState.payment, action) {
  switch (action.type) {
    case types.CLEAR_PAYMENT: {
      return null;
    }
    default:
      return state;
  }
}
