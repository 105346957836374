import * as types from '../../types';

export const initialState = {
  loading: false,
  currentStep: 'findAccount',
  errors: null,
  registration: null,
  accounts: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RESET_SCREEN: {
      return initialState;
    }
    case types.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: payload
      };
    }
    case types.CREATE_REGISTRATION_REJECTED: {
      return {
        ...state,
        errors: payload.response.data.errors
      };
    }
    case types.CREATE_REGISTRATION_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case types.CREATE_REGISTRATION_FULFILLED: {
      return {
        ...state,
        registration: payload.data,
        accounts: payload.data.receivable_accounts,
        currentStep: 'register',
        loading: false
      };
    }
    case types.CREATE_REGISTRATION_ACCOUNT_REJECTED: {
      return {
        ...state,
        errors: payload.response.data.errors
      };
    }
    case types.CREATE_REGISTRATION_ACCOUNT_FULFILLED: {
      const accounts = [...state.accounts];
      accounts.push(payload.data.receivable_account);
      return {
        ...state,
        accounts,
        currentStep: 'register'
      };
    }
    case types.CREATE_USER_REJECTED: {
      return {
        ...state,
        errors: payload.response.data.errors
      };
    }
    case types.CLEAR_ERRORS: {
      return {
        ...state,
        errors: null
      };
    }
    case types.NEW_ACCOUNT: {
      return {
        ...state,
        currentStep: 'findAccount'
      };
    }
    default:
      return state;
  }
};
