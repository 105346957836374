import * as types from '../types';
import initialState from './initialState';

export default function externalPaymentReducer(state = initialState.externalPayment, action) {
  switch (action.type) {
    case types.FETCH_EXTERNAL_PAYMENT_FULFILLED:
      return action.payload.data;
    case types.CLEAR_EXTERNAL_PAYMENT:
      return null;
    default:
      return state;
  }
}
