import * as types from '../../types';
import Registration from '../../../api/registration';
import RegistrationAccount from '../../../api/registrationAccount';
import UserAPI from '../../../api/userAPI';

export function createRegistration(account) {
  return function(dispatch) {
    dispatch({
      type: types.CREATE_REGISTRATION,
      payload: Registration.create(account)
    }).catch((error) => {});
  };
}

export function createRegistrationAccount(registration, account) {
  return function(dispatch) {
    dispatch({
      type: types.CREATE_REGISTRATION_ACCOUNT,
      payload: RegistrationAccount.create(registration, account)
    }).catch((error) => {});
  };
}

export function createUser(registration, user) {
  return function(dispatch) {
    dispatch({
      type: types.CREATE_USER,
      payload: UserAPI.create(registration, user)
    }).catch((error) => {});
  };
}

export function newAccount() {
  return {
    type: types.NEW_ACCOUNT
  };
}

export {
  resetScreen,
  clearErrors,
  setCurrentStep
} from '../../actions';
