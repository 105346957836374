import * as types from '../types';
import initialState from './initialState';

export default function savedBillRecurrenceReducer(state = initialState.savedBillRecurrence, action) {
  switch (action.type) {
    case types.SAVE_BILL_RECURRENCE_FULFILLED: {
      return action.payload.data;
    }
    case types.CLEAR_SAVED_BILL_RECURRENCE: {
      return null;
    }
    default:
      return state;
  }
}
