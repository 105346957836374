import React from 'react';
import { PropTypes } from 'prop-types';
import PasswordStrength from '../../components/common/PasswordStrength';
import Link from '../../components/common/Link';
import FormErrors from 'shared/components/FormErrors';

class AccountScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showEditEmail: false,
      showEditPassword: false,
      submitDisabled: true,
      passwordPristine: false,
      showAccountMenu: true,
      newPassword: "",
      showErrors: false
    };

    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.validatePasswordConf = this.validatePasswordConf.bind(this);
    this.resetAccountMenu = this.resetAccountMenu.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser.updated_successfully) {
      this.resetAccountMenu();
    } else if (nextProps.errors.user && nextProps.errors.user.length) {
      this.setState({ showErrors: true });
    }
  }

  clearErrors() {
    this.setState({ showErrors: false });
  }

  formErrors() {
    if (this.state.showErrors) {
      return (
        <FormErrors
          errors={this.props.errors.user}
          dismiss={this.clearErrors} />
      );
    }
  }

  accountMenu() {
    if (this.state.showAccountMenu) {
      return (
        <section>
          <h1>{this.props.i18n.portal.payer.account.manage_profile}</h1>
          <mark className="user-email">{this.props.currentUser.email}</mark>
          <ul className="account-actions">
            <li>
              <Link onClick={this.changeEmail} >
                {this.props.i18n.common.change}{' '}
                {this.props.content.email_label}
              </Link>
            </li>
            <li>
              <Link onClick={this.changePassword} >
                {this.props.i18n.portal.payer.account.change_password}
              </Link>
            </li>
          </ul>
        </section>
      );
    }
  }

  changeEmailForm() {
    const inputClass =
      this.state.showErrors ? 'form-group row has-error' : 'form-group row';

    if (this.state.showEditEmail) {
      return (
        <section>
          <h1>
            {this.props.i18n.common.change}{' '}
            {this.props.content.email_label}
          </h1>
          <form
            className="form-themed"
            onSubmit={this.handleSubmitForm}
            name="changeEmailForm"
            ref="changeEmailForm"
            noValidate="novalidate">
            <section className="account-form-section">
              <div className={inputClass}>
                <label className="col-form-label col-sm-5 text-sm-right" htmlFor="new_email">
                  {this.props.i18n.common.new}{' '}
                  {this.props.content.email_label}
                </label>
                <div className="col-sm-5">
                  <input
                    required
                    name="new_email"
                    ref="new_email"
                    type="text"
                    className="form-control"
                    onChange={this.validateEmail}
                  />
                </div>
              </div>
            </section>
            {this.footer()}
          </form>
        </section>
      );
    }
  }

  passwordChange(state) {
    this.setState({
      submitDisabled: !state.isValid,
      newPassword: state.password,
    });

    if (state.isValid) {
      if (this.refs.email == null) {
        this.setState({ passwordPristine: true });
      } else if (this.refs.email.value.length > 0) {
        this.setState({ submitDisabled: false });
      }
    }
  }

  changePasswordForm() {
    if (this.state.showEditPassword) {
      return (
        <section>
          <h1>
            {this.props.i18n.common.change}{' '}
            {this.props.i18n.common.password}
          </h1>
          <form
            className="form-themed"
            onSubmit={this.handleSubmitForm}
            name="changePasswordForm"
            ref="changePasswordForm"
            noValidate="novalidate">
            <section className="account-form-section">
              <div className="form-group row">
                <label className="col-form-label col-sm-5 text-sm-right" htmlFor="new_password">
                  {this.props.i18n.portal.payer.account.new_password}
                </label>
                <div className="col-sm-5">
                  <PasswordStrength
                    content={this.props.content}
                    labels={this.props.i18n.portal.password_validation_helper}
                    changeCallback={this.passwordChange}
                    inputProps={{ name: 'new_password', ref: 'new_password' }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-5 text-sm-right" htmlFor="password_confirmation">
                  {this.props.i18n.portal.payer.account.confirm_new_password}
                </label>
                <div className="col-sm-5">
                  <input
                    required
                    className="form-control"
                    name="password_confirmation"
                    type="password"
                    ref="password_confirmation"
                    onChange={this.validatePasswordConf}
                  />
                </div>
              </div>
            </section>
            {this.footer()}
          </form>
        </section>
      );
    }
  }

  footer() {
    return (
      <footer className="form-actions">
        <Link onClick={this.resetAccountMenu} className="btn btn-link" >
          {this.props.i18n.common.cancel}
        </Link>
        <input className="btn-lg btn primary-btn" type="submit" value={this.props.i18n.common.continue} disabled={this.state.submitDisabled} />
      </footer>
    );
  }

  handleSubmitForm($event) {
    $event.preventDefault();
    if (this.refs.new_email == null) {
      const newPassword = this.state.newPassword;
      this.props.updateUser(this.props.currentUser, newPassword, this.props.currentUser.email);
      this.resetAccountMenu();
    } else {
      const new_email = this.refs.new_email.value;
      this.props.updateUser(this.props.currentUser, this.props.currentUser.password, new_email);
    }
  }

  changeEmail($event) {
    this.props.clearUpdatedUserCheck();
    this.props.clearUserErrors();
    $event.preventDefault();
    this.setState({
      showEditEmail: !this.state.showEditPassword,
      showAccountMenu: !this.state.showAccountMenu
    });
  }

  resetAccountMenu() {
    this.setState({
      showEditEmail: false,
      showEditPassword: false,
      showAccountMenu: true,
      submitDisabled: true
    });
  }

  changePassword() {
    this.setState({
      showEditPassword: !this.state.showEditPassword,
      showAccountMenu: !this.state.showAccountMenu
    });
  }

  validateEmail(state) {
    if (this.refs.new_email.value != "") {
      this.setState({ submitDisabled: false });
    }
  }

  validatePasswordConf(state) {
    if (this.refs.password_confirmation.value == this.state.newPassword) {
      if (this.state.passwordPristine == true) {
        this.setState({ submitDisabled: false });
      }
    } else if (this.refs.password_confirmation.value != this.state.newPassword) {
      this.setState({ submitDisabled: true });
    }
  }

  profile() {
    return (
      <div>
        <h1 className="section-heading">
          {this.props.content.menu_account_label}
        </h1>
        <div className="card panel-themed">
          <div className="card-body">
            {this.accountMenu()}
            {this.changeEmailForm()}
            {this.changePasswordForm()}
          </div>
        </div>
      </div>
    );
  }

  subscription() {
    if (!this.props.config.notice_rules.length) return;

    return (
      <div>
        <h1 className="section-heading">
          {this.props.i18n.portal.payer.account.subscription}
        </h1>
        <div className="card panel-themed">
          <div className="card-body">
            <section>
              <h1>
                {this.props.i18n.portal.payer.account.manage_subscription}
                {' '}
                {this.subscriptionIndicator()}
              </h1>
              {this.subscriptionItems()}
            </section>
          </div>
        </div>
      </div>
    );
  }

  subscriptionIndicator() {
    if (this.props.subscriptionLoading) {
      return <i className="fa fa-fw fa-spinner fa-spin" />;
    }
  }

  handleSubscriptionChange = (event, noticeRule, subscription) => {
    if (event.target.checked) {
      this.props.subscribe(noticeRule);
    }
    else {
      this.props.unsubscribe(subscription);
    }
  }

  subscriptionItems() {
    const { portal_user } = this.props.currentUser;
    const { notice_rules } = this.props.config;
    const { notice_subscriptions } = portal_user;

    return notice_rules.map((noticeRule, i) => {
      const subscription = notice_subscriptions.find(subscription => {
        return subscription.notice_rule_id === noticeRule.id;
      });

      return (
        <div className="checkbox" key={noticeRule.id}>
          <label>
            <input
              type="checkbox"
              checked={subscription != null}
              onChange={(event) => {
                this.handleSubscriptionChange(event, noticeRule, subscription);
              }}
            />
            {noticeRule.name}
          </label>
        </div>
      );
    });
  }

  render() {
    return (
      <div id="account-settings">
        {this.formErrors()}
        <div className="content-main">
          {this.profile()}
          <br />
          {this.subscription()}
        </div>
      </div>
    );
  }
}

AccountScreen.propTypes = {
  i18n: PropTypes.object,
  config: PropTypes.object,
  content: PropTypes.object,
  currentUser: PropTypes.object,
  errors: PropTypes.object,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  subscriptionLoading: PropTypes.bool,
  updateUser: PropTypes.func,
  clearUpdatedUserCheck: PropTypes.func,
  clearUserErrors: PropTypes.func,
};

export default AccountScreen;
