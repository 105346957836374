import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Link, hashHistory } from 'react-router';
import BillsOverview from './components/BillsOverview';
import AccountsOverview from './components/AccountsOverview';
import TabSet from '../../components/common/TabSet';
import Tab from '../../components/common/Tab';
import Icon from 'shared/components/Icon';
import Button from '../../components/common/Button';
import PaymentsList from './components/PaymentsList';
import ExternalBillsTable from '../../components/common/ExternalBillsTable';
import ExternalBillAPI from '../../api/externalBillAPI';
import ExternalPaymentsTable from '../../components/common/ExternalPaymentsTable';
import ExternalPaymentAPI from '../../api/externalPaymentAPI';
import PaymentAPI from '../../api/paymentAPI';
import BillDocumentAPI from '../../api/billDocumentAPI';
import BillDocumentsModal from '../../components/common/BillDocumentsModal';
import NoticePopover from '../../components/common/NoticePopover';
import PaymentDetailsModal from './components/PaymentDetailsModal';
import PortalAgreementModal from './components/PortalAgreementModal';
import ReactMarkdown from 'react-markdown';

class DashboardScreen extends Component {

  constructor(props) {
    super(props);

    this.togglePopover = this.togglePopover.bind(this);
    this.state = {
      activeTab: "transactions",
      isPaymentDetailsModalOpen: false,
      isAgreementModalOpen: false,
      popoverOpen: false,
      accountsFilter: ''
    };

    this.exportData = this.exportData.bind(this);
    this.transactionsTabSelected = this.transactionsTabSelected.bind(this);
    this.externalPaymentsTabSelected = this.externalPaymentsTabSelected.bind(this);
    this.externalBillsTabSelected = this.externalBillsTabSelected.bind(this);
    this.accountsFilterChanged = this.accountsFilterChanged.bind(this);
    this.togglePaymentDetailsModal = this.togglePaymentDetailsModal.bind(this);
    this.externalPaymentSelected = this.externalPaymentSelected.bind(this);
    this.filterExternalPayments = this.filterExternalPayments.bind(this);
    this.pageExternalPayments = this.pageExternalPayments.bind(this);
    this.sortExternalPayments = this.sortExternalPayments.bind(this);
    this.downloadBillDocument = this.downloadBillDocument.bind(this);
    this.filterExternalBills = this.filterExternalBills.bind(this);
    this.pageExternalBills = this.pageExternalBills.bind(this);
    this.sortExternalBills = this.sortExternalBills.bind(this);
    this.submitPortalRecurrence = this.submitPortalRecurrence.bind(this);
    this.acceptPortalAgreement = this.acceptPortalAgreement.bind(this);
    this.declinePortalAgreement = this.declinePortalAgreement.bind(this);
    this.removeAccountRecurrence = this.removeAccountRecurrence.bind(this);
    this.pageSplits = this.pageSplits.bind(this);
    this.filterSplits = this.filterSplits.bind(this);
    this.sortSplits = this.sortSplits.bind(this);
  }

  componentWillMount() {
    this.checkPasswordChangeRequired();
    this.checkPortalAgreement();
    this.fetchOverview();
    this.props.clearPayments();
    this.props.fetchPayments();
    this.props.fetchPayMethods();
  }

  componentWillReceiveProps(nextProps) {
    this.handleExternalPayment(nextProps);
    this.handleDeletedPayment(nextProps);
    this.handleUnconfirmedPayment(nextProps);
  }

  handleExternalPayment(nextProps) {
    if (nextProps.externalPayment) {
      this.setState({
        selectedPayment: nextProps.externalPayment,
        isPaymentDetailsModalOpen: true
      });
      this.props.clearExternalPayment();
    }
  }

  handleDeletedPayment(nextProps) {
    if (nextProps.deletedPayment) {
      this.props.fetchPayments();
      this.fetchOverview();
      this.props.clearDeletedPayment();
    }
  }

  handleUnconfirmedPayment(nextProps) {
    if (nextProps.unconfirmedPayment) {
      this.props.clearUnconfirmedPayment();
      hashHistory.push('payer/bill_payment');
    }
  }

  pageSplits(data) {
    this.props.fetchSplits({
      paymentId: this.props.fetchedPayment.id,
      page: data.selected,
      query: this.props.splitsQuery,
      sort_order: this.props.splitsSortOrder
    });
  }

  filterSplits(query, props) {
    this.props.setSplitsQuery(query);

    this.props.fetchSplits({
      paymentId: this.props.fetchedPayment.id,
      query,
      sort_order: this.props.splitsSortOrder,
    });
  }

  sortSplits(sortOrder) {
    this.props.setSplitsSortOrder(sortOrder);
    this.props.fetchSplits({
      paymentId: this.props.fetchedPayment.id,
      sort_order: sortOrder,
      query: this.props.splitsQuery
    });
  }

  checkPasswordChangeRequired() {
    if (this.props.currentUser.password_change_required) {
      hashHistory.push('reset_password');
    }
  }

  checkPortalAgreement() {
    const required = this.props.config.portal_agreement_required;
    const accepted = this.props.currentUser.portal_user.portal_agreement_accepted;
    if (required && !accepted) {
      this.setState({isAgreementModalOpen: true});
    }
  }

  agreementModal() {
    return (
      <PortalAgreementModal
        agreementLabel={this.props.content.portal_agreement_label}
        agreementNotice={this.props.content.portal_agreement_notice}
        acceptAction={this.acceptPortalAgreement}
        declineAction={this.declinePortalAgreement}
        isOpen={this.state.isAgreementModalOpen}
        i18n={this.props.i18n} />
    );
  }

  acceptPortalAgreement() {
    this.setState({isAgreementModalOpen: false});
    this.props.createPortalAgreement(this.props.currentUser.portal_user);
  }

  declinePortalAgreement() {
    const logoutUrl = this.props.config.logout_url;
    const redirect = logoutUrl ? function() { window.location = logoutUrl; } : null;
    this.props.logout(redirect);
  }

  fetchOverview() {
    if (this.props.config.feature.bill_search_enabled) {
      this.props.fetchBillsOverview();
    }
    else {
      this.props.fetchAccounts(this.props.currentUser.portal_user);
    }
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  billsOverview() {
    if (this.props.config.feature.bill_search_enabled) {
      return (
        <BillsOverview
          loading={this.props.loading.fetch_bills_overview}
          overviewLabel={this.props.i18n.portal.dashboard.overview}
          dueAmount={this.props.bills.overview.amount_due}
          dueAmountLabel={this.props.content.due_label}
          openAmount={this.props.bills.overview.amount_open}
          openAmountLabel={this.props.i18n.portal.dashboard.all_open}
          showPaymentLink={this.props.abilities.manage_portal_payment}
          paymentLinkLabel={this.props.i18n.portal.dashboard.make_payment}
          viewInvoicesLabel={this.props.i18n.portal.dashboard.view_invoices}
          config={this.props.config}
          paymentLinkTo="/payer/bill_payment" />
      );
    }
  }

  accountsOverview() {
    if (!this.props.config.feature.bill_search_enabled) {
      return (
        <AccountsOverview
          {...this.props}
          accounts={this.props.accounts}
          i18n={this.props.i18n}
          submitPortalRecurrence={this.submitPortalRecurrence}
          removeAccountRecurrence={this.removeAccountRecurrence}
          accountRecurrenceErrors={this.props.accountRecurrenceErrors}
          clearAccountRecurrenceErrors={this.props.clearAccountRecurrenceErrors}
          labelConfig={this.props.config.feature} />
      );
    }
  }

  submitPortalRecurrence(portalPayer, recurrence, account) {
    this.props.createAccountRecurrence(portalPayer, recurrence, account);
    this.props.fetchPayments();
  }

  activitySection() {
    return (
      <section className="dash-section activity-section">
        <h1 className="section-heading">
          {this.props.i18n.portal.dashboard.activity.heading}
        </h1>
        <div className="card panel-themed activities">
          <div className="card-header d-flex">
            {this.activityToolbar()}
            {this.activityTabs()}
          </div>
          <div className="card-body">
            {this.transactionsTab()}
            {this.externalPaymentsTab()}
            {this.externalBillsTab()}
          </div>
        </div>
      </section>
    );
  }

  activityToolbar() {
    return (
      <div className="actions-toolbar btn-group-sm btn-group order-2 ml-auto">
        <Button icon="file-excel far" onClick={this.exportData}>
          {this.props.i18n.common.export}
        </Button>
        {this.paymentHistoryNoticeButton()}
        {this.paymentHistoryNotice()}
      </div>
    );
  }

  activityTabs() {
    return (
      <TabSet card>
        <Tab
          show
          active={this.state.activeTab == "transactions"}
          label={this.props.content.payments_tab_label}
          onSelect={this.transactionsTabSelected} />
        <Tab
          active={this.state.activeTab == "externalPayments"}
          label={this.props.content.external_payments_tab_label}
          onSelect={this.externalPaymentsTabSelected}
          show={this.props.config.external_payments_enabled} />
        <Tab
          active={this.state.activeTab == "externalBills"}
          label={this.props.content.external_bills_tab_label}
          onSelect={this.externalBillsTabSelected}
          show={this.props.config.external_bills_enabled} />
      </TabSet>
    );
  }

  // --- TRANSACTIONS
  transactionsTabSelected() {
    this.setState({activeTab: "transactions"});
  }

  externalPaymentsTabSelected() {
    this.props.fetchExternalPayments({ retrieve: true });
    this.setState({activeTab: "externalPayments"});
  }

  externalBillsTabSelected() {
    this.props.fetchExternalBills({ retrieve: true });
    this.setState({activeTab: "externalBills"});
  }

  transactionsTab() {
    if (this.state.activeTab === "transactions") {
      const paymentsData = this.props.payments.data;
      const loading = this.props.loading.fetch_payments && !paymentsData;
      const listProps = {
        ...this.props,
        paymentsData,
        loading,
        onPageChange: this.pagePayments.bind(this),
        onSplitsPageChange: this.pageSplits.bind(this),
        onSplitsFilterChange: this.filterSplits.bind(this),
        onSplitsSortChange: this.sortSplits.bind(this)
      };
      return (
        <div>
          {this.transactionsFilter()}
          <section className="transactions-section">
            <h1 className="sr-only">{this.props.i18n.portal.ada_headers.transactions_section}</h1>
            <PaymentsList
              {...listProps}
              onEditPayment={this.props.unconfirmPayment}
            />
          </section>
        </div>
      );
    }
  }

  transactionsFilter() {
    const paymentsData = this.props.payments.data;
    const filteringAccounts = this.state.accountsFilter.length;
    const hasPayments = paymentsData && paymentsData.total_payments > 0;
    const billSearchEnabled = this.props.config.feature.bill_search_enabled;
    if ((hasPayments || filteringAccounts) && !billSearchEnabled) {
      return (
        <section className="transactions-filter">
          <div className="input-group select-input-group-right">
            <div className="input-group-prepend">
              <Icon type="filter" />
            </div>
            <select
              className="form-control"
              name="accountFilter"
              onChange={this.accountsFilterChanged}>
              <option value="">
                {this.props.i18n.portal.dashboard.all_accounts}
              </option>
              {this.accountsOptions()}
            </select>
          </div>
        </section>
      );
    }
  }

  accountsFilterChanged($event) {
    const accountsFilter = $event.target.value;
    this.setState({accountsFilter});
    this.props.fetchPayments({
      "receivable_accounts.id": accountsFilter
    });
  }

  accountsOptions() {
    const accounts = this.props.accounts.data ? this.props.accounts.data.accounts : [];
    return accounts.map((account, i) =>
      <option key={account.id} value={account.id}>
        {account.account_label}
      </option>
    );
  }

  pagePayments(data) {
    const accountId = this.state.accountsFilter;
    this.props.fetchPayments({
      page: data.selected,
      "receivable_accounts.id": accountId
    });
  }

  paymentHistoryNoticeButton() {
    if (this.props.content.payment_history_notice) {
      return (
        <Button id="PaymentHistoryPopover" onClick={this.togglePopover}>
          <i className="fa icon-help"></i>
        </Button>
      );
    }
  }

  removeAccountRecurrence(portalPayer, recurrence) {
    this.props.deleteAccountRecurrence(portalPayer, recurrence);
    this.props.fetchPayments();
  }

  paymentHistoryNotice() {
    if (this.props.content.payment_history_notice) {
      return (
        <NoticePopover
          placement={"top"}
          isOpen={this.state.popoverOpen}
          target={"PaymentHistoryPopover"}
          toggle={this.togglePopover}
          source={this.props.content.payment_history_notice}
          className="payment-history-notice top"
          containerTagName="span" />
      );
    }
  }

  exportData() {
    switch (this.state.activeTab) {
      case "transactions":
        window.location = PaymentAPI.paymentsExportUrl();
        break;
      case "externalPayments":
        window.location = ExternalPaymentAPI.paymentsExportUrl({
          query: this.state.externalPaymentsQuery
        });
        break;
      case "externalBills":
        window.location = ExternalBillAPI.billsExportUrl({
          query: this.state.externalBillsQuery
        });
        break;
    }
  }

  paymentDetailsModal() {
    return (
      <PaymentDetailsModal
        sendEmailConfirmation={this.props.sendEmailConfirmation}
        payment={this.state.selectedPayment}
        isOpen={this.state.isPaymentDetailsModalOpen}
        toggle={this.togglePaymentDetailsModal}
        onSplitsPageChange={this.pageSplits}
        onSplitsFilterChange={this.filterSplits}
        onSplitsSortChange={this.sortSplits}
        fetchBillDocuments={this.props.fetchBillDocuments}
        fetchExternalBillDocuments={this.props.fetchExternalBillDocuments}
        config={this.props.config}
        content={this.props.content}
        i18n={this.props.i18n} />
    );
  }

  togglePaymentDetailsModal() {
    this.setState({
      isPaymentDetailsModalOpen: !this.state.isPaymentDetailsModalOpen
    });
  }

  // --- EXTERNAL PAYMENTS
  externalPaymentsTab() {
    if (this.state.activeTab === "externalPayments") {
      return (
        <ExternalPaymentsTable
          selectable
          useExternal
          data={this.props.externalPayments.data}
          itemsPerPage={this.props.config.pagination_size}
          onRowClick={this.externalPaymentSelected}
          showBillExternalKeyAsLink={this.props.config.bill_document_retriever_enabled}
          onBillExternalKeyClick={this.props.fetchExternalBillDocuments}
          externalSetFilter={this.filterExternalPayments}
          externalSetPage={this.pageExternalPayments}
          externalChangeSort={this.sortExternalPayments}
          i18n={this.props.i18n}
          content={this.props.content}
          config={this.props.config} />
      );
    }
  }

  externalPaymentSelected(externalPayment) {
    this.props.fetchExternalPayment(externalPayment.id);
  }

  filterExternalPayments(query) {
    this.setState({ externalPaymentsQuery: query });
    this.props.fetchExternalPayments({
      query,
      sort_order: this.state.externalPaymentsSortOrder
    });
  }

  pageExternalPayments(page) {
    this.props.fetchExternalPayments({
      page,
      query: this.state.externalPaymentsQuery,
      sort_order: this.state.externalPaymentsSortOrder
    });
  }

  sortExternalPayments(sortOrder) {
    this.setState({ externalPaymentsSortOrder: sortOrder });
    this.props.fetchExternalPayments({
      sort_order: sortOrder,
      query: this.state.externalPaymentsQuery
    });
  }
  // ---

  // --- EXTERNAL BILLS
  externalBillsTab() {
    if (this.state.activeTab === "externalBills") {
      return (
        <ExternalBillsTable
          useExternal
          data={this.props.externalBills.data}
          itemsPerPage={this.props.config.pagination_size}
          showBillExternalKeyAsLink={this.props.config.bill_document_retriever_enabled}
          onBillExternalKeyClick={this.props.fetchExternalBillDocuments}
          externalSetFilter={this.filterExternalBills}
          externalSetPage={this.pageExternalBills}
          externalChangeSort={this.sortExternalBills}
          i18n={this.props.i18n}
          content={this.props.content}
          config={this.props.config} />
      );
    }
  }

  filterExternalBills(query) {
    clearTimeout(this.externalBillSearch);
    this.setState({ externalBillsQuery: query });
    this.externalBillSearch = setTimeout(
      (func, query, sort) => { func({query, sort_order: sort}); },
      1500,
      this.props.fetchExternalBills,
      query,
      this.state.externalBillsSortOrder);
  }

  pageExternalBills(page) {
    this.props.fetchExternalBills({
      page,
      query: this.state.externalBillsQuery,
      sort_order: this.state.externalBillsSortOrder
    });
  }

  sortExternalBills(sortOrder) {
    this.setState({ externalBillsSortOrder: sortOrder });
    this.props.fetchExternalBills({
      sort_order: sortOrder,
      query: this.state.externalBillsQuery
    });
  }

  billDocumentsModal() {
    return (
      <BillDocumentsModal
        isOpen={this.props.billDocuments != null}
        toggle={this.props.clearBillDocuments}
        billDocuments={this.props.billDocuments}
        onDocumentClick={this.downloadBillDocument}
        content={this.props.content}
        i18n={this.props.i18n} />
    );
  }

  downloadBillDocument(billDocument) {
    const external = billDocument.billable_type == "ExternalBill";
    window.location = BillDocumentAPI.downloadUrl(billDocument, external);
  }
  // ---

  getDashboardClass() {
    if (this.props.config.feature.bill_search_enabled) {
      return 'invoicer-dashboard';
    }
    else {
      return 'account-dashboard';
    }
  }

  welcomeSection() {
    if (this.props.showWelcome && this.props.config.welcome_section_enabled) {
      return (
        <section className="dash-section overview-section">
          <h1 className="section-heading">
            {this.props.content.welcome_section_label}
          </h1>
          <div className="card panel-themed">
            <div className="card-body">
              <ReactMarkdown
                escapeHtml={false}
                source={this.props.content.welcome_section_content}
                className="welcome-section-content"
              />
              <Link
                className="hide-welcome"
                onClick={this.props.hideWelcome}
                tabIndex="-1"
              >
                <Icon type="times" />
              </Link>
            </div>
          </div>
        </section>
      );
    }
  }

  render() {
    const dashboardClass = this.getDashboardClass();
    return (
      <div id="payer-dashboard" className={dashboardClass}>
        <div className="content-main">
          {this.welcomeSection()}
          {this.billsOverview()}
          {this.accountsOverview()}
          {this.activitySection()}
          {this.billDocumentsModal()}
          {this.paymentDetailsModal()}
          {this.agreementModal()}
        </div>
      </div>
    );
  }
}

DashboardScreen.propTypes = {
  i18n: PropTypes.object,
  config: PropTypes.object,
  content: PropTypes.object,
  loading: PropTypes.object,
  currentUser: PropTypes.object,
  bills: PropTypes.object,
  abilities: PropTypes.object,
  accounts: PropTypes.object,
  payments: PropTypes.object,
  externalBills: PropTypes.object,
  externalPayments: PropTypes.object,
  billDocuments: PropTypes.object,
  clearBillDocuments: PropTypes.func,
  fetchSplits: PropTypes.func,
  fetchedPayment: PropTypes.object,
  fetchExternalPayment: PropTypes.func,
  splitsQuery: PropTypes.string,
  splitsSortOrder: PropTypes.string,
  fetchBillDocuments: PropTypes.func,
  setSplitsQuery: PropTypes.func,
  setSplitsSortOrder: PropTypes.func,
  fetchExternalBillDocuments: PropTypes.func,
  deleteAccountRecurrence: PropTypes.func,
  clearPayments: PropTypes.func,
  fetchPayments: PropTypes.func,
  fetchPayMethods: PropTypes.func,
  clearExternalPayment: PropTypes.func,
  clearDeletedPayment: PropTypes.func,
  fetchBillsOverview: PropTypes.func,
  fetchAccounts: PropTypes.func,
  createAccountRecurrence: PropTypes.func,
  fetchExternalPayments: PropTypes.func,
  fetchPortalAgreement: PropTypes.func,
  clearPortalAgreement: PropTypes.func,
  logout: PropTypes.func,
  createPortalAgreement: PropTypes.func,
  fetchExternalBills: PropTypes.func,
  unconfirmPayment: PropTypes.func.isRequired,
  clearUnconfirmedPayment: PropTypes.func.isRequired,
  accountRecurrenceErrors: PropTypes.array,
  clearAccountRecurrenceErrors: PropTypes.func.isRequired,
  hideWelcome: PropTypes.func.isRequired,
  showWelcome: PropTypes.bool,
  sendEmailConfirmation: PropTypes.func
};

export default DashboardScreen;
