import * as types from '../types';
import initialState from './initialState';

export default function resetPasswordReducer(state = initialState.resetPassword, action) {
  switch (action.type) {
    case types.RESET_PASSWORD_FULFILLED: {
      return true;
    }
    case types.CLEAR_PASSWORD_RESET: {
      return false;
    }
    case types.UPDATE_PASSWORD_FULFILLED: {
      return true;
    }
    default:
      return state;
  }
}
