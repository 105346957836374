import React from 'react';
import { PropTypes } from 'prop-types';
import { hashHistory } from 'react-router';
import Link from '../../../components/common/Link';
import Spinner from '../../../components/common/Spinner';
import Pagination from 'shared/components/Pagination';
import Modal from '../../../components/common/Modal';
import PaymentDetailsModal from './PaymentDetailsModal';
import format from '../../../utils/formatUtils';

class PaymentsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDetailsModalOpen: false,
      isEditPaymentModalOpen: false
    };
    this.toggleDetailsModal = this.toggleDetailsModal.bind(this);
    this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
    this.deleteSelectedPayment = this.deleteSelectedPayment.bind(this);
    this.toggleEditPaymentModal = this.toggleEditPaymentModal.bind(this);
    this.editSelectedPayment = this.editSelectedPayment.bind(this);
  }

  paymentsList(payments) {
    return (
      <div className="transactions">
        {payments.map((payment, i) => this.paymentItem(payment, i))}
      </div>
    );
  }

  handleKeyUp(payment, event) {
    if (event.keyCode === 13) {
      this.toggleSelectedPayment(payment);
    }
  }

  paymentItem(payment, i) {
    let classes = ['transaction'];
    if (this.isSelectedPayment(payment)) classes.push('selected');
    const togglePayment = this.toggleSelectedPayment.bind(this, payment);
    const handleKeyUp = this.handleKeyUp.bind(this, payment);
    return (
      <div
        key={payment.id}
        className={classes.join(' ')}
        onClick={togglePayment}
        onKeyUp={handleKeyUp}
        role="button"
        tabIndex={0}
      >
        <div className="row">
          <div className="col-4">
            <div className="row">
              {this.confirmationCode(payment)}
              {this.paymentDate(payment)}
            </div>
          </div>
          <div className="col-4 account-details-wrapper">
            {this.accountDetails(payment)}
          </div>
          <div className="col-4 amount-and-status-col">
            <div className="row">
              {this.paymentAmount(payment)}
              {this.paymentStatus(payment)}
            </div>
          </div>
        </div>
        {this.paymentActions(payment)}
      </div>
    );
  }

  paymentActions(payment) {
    const showPayment = this.showPayment.bind(this, payment);
    return (
      <section className="transacation-actions">
        <h1 className="sr-only">{this.props.i18n.portal.ada_headers.account_summary}</h1>
        <Link
          stopPropagation
          className="action-btn btn btn-link"
          onClick={showPayment}>
          {this.props.i18n.portal.dashboard.view_details}
        </Link>
        {this.editActions(payment)}
      </section>
    );
  }

  editActions(payment) {
    if (this.props.abilities.manage_portal_payment && payment.is_scheduled) {
      return (
        <span>
          {this.editLink(payment)}
          {this.deleteLink(payment)}
        </span>
      );
    }
  }

  editLink(payment) {
    const editEnabled = this.props.config.payment_edit_enabled;
    const editRecurrenceEnabled = this.props.config.bill_recurrence_payment_edit_enabled;
    const enabled = editEnabled && (payment.is_recurring ? editRecurrenceEnabled : true);
    const editPayment = this.editPayment.bind(this, payment);
    if (enabled) {
      return (
        <Link
          stopPropagation
          className="action-btn btn btn-link"
          onClick={editPayment}
          icon="pencil-alt">
          {this.props.i18n.common.edit}
        </Link>
      );
    }
  }

  deleteLink(payment) {
    const deleteEnabled = this.props.config.bill_recurrence_payment_destroy_enabled;
    const enabled = payment.is_recurring ? deleteEnabled : true;
    const deletePayment = this.deletePayment.bind(this, payment);
    if (enabled) {
      return (
        <Link
          stopPropagation
          className="action-btn btn btn-link btn-delete"
          onClick={deletePayment}
          icon="trash-alt">
          {this.props.i18n.common.delete}
        </Link>
      );
    }
  }

  isSelectedPayment(payment) {
    return this.state.selectedPayment == payment && payment.is_scheduled;
  }

  toggleSelectedPayment(payment) {
    if (!payment.is_scheduled) {
      this.setState({selectedPayment: payment});
      this.showPayment(payment);
    }
    else if (this.isSelectedPayment(payment)) {
      this.setState({selectedPayment: null});
    }
    else {
      this.setState({selectedPayment: payment});
    }
  }

  paymentDetailsModal() {
    return (
      <PaymentDetailsModal
        {...this.props}
        payment={this.props.fetchedPayment}
        loading={this.props.fetchingPayment}
        isOpen={this.state.isDetailsModalOpen}
        toggle={this.toggleDetailsModal}
      />
    );
  }

  toggleDetailsModal() {
    this.setState({ isDetailsModalOpen: !this.state.isDetailsModalOpen });
  }

  showPayment(payment) {
    this.props.fetchPayment(payment.id);
    this.setState({ isDetailsModalOpen: true });
  }

  editPayment(payment) {
    if (this.props.config.feature.bill_search_enabled) {
      this.toggleEditPaymentModal();
    }
    else {
      hashHistory.push(`payer/accounts/${payment.account.id}/payment/${payment.id}`);
    }
  }

  deletePayment(payment) {
    this.toggleConfirmDeleteModal();
  }

  paymentAmount(payment) {
    return (
      <div className="amount col-md-6">
        {format.currency(payment.amount)}
      </div>
    );
  }

  paymentStatus(payment) {
    let statusLabel = null;
    let statusText = null;

    if (payment.is_pending_submission) {
      statusLabel = "warning";
      statusText = this.props.i18n.portal.dashboard.pending_submission;
    }
    else if (payment.is_scheduled) {
      statusLabel = "primary";
      statusText = this.props.i18n.portal.dashboard.scheduled;
    }
    else if (payment.is_processing) {
      statusLabel = "warning";
      statusText = this.props.content.payment_status_processing;
    }
    else if (payment.is_completed) {
      statusLabel = "success";
      statusText = this.props.i18n.portal.dashboard.completed;
    }
    else if (payment.is_cancelled || payment.is_failed) {
      statusLabel = "danger";
      statusText = this.props.i18n.portal.dashboard.failed;
    }

    return (
      <div className="status col-md-6">
        {this.autopayStatus(payment)}
        <span className={`badge badge-${statusLabel}`}>
          {statusText}
        </span>
      </div>
    );
  }

  autopayStatus(payment) {
    if (payment.is_recurring) {
      return (
        <div>
          <span className="badge badge-info">
            {this.props.i18n.portal.dashboard.autopay}
          </span>
        </div>
      );
    }
  }

  accountDetails(payment) {
    const invoicer = this.props.config.feature.bill_search_enabled;
    const getDetails = invoicer ? this.invoicerAccountDetails : this.nonInvoicerAccountDetails;
    const details = getDetails(payment);
    return (
      <div className="account-details">
        {details}
      </div>
    );
  }

  invoicerAccountDetails(payment) {
    return (
      <div className="row">
        <div className="account-num col-md-4">
          {payment.account.external_key}
        </div>
        <div className="account-name col-md-8">
          {payment.account.payer.name}
        </div>
      </div>
    );
  }

  nonInvoicerAccountDetails(payment) {
    const account = payment.account;
    const accountType = account.account_type ? account.account_type.name : '';
    return (
      <div className="row">
        <div className="account-type">
          {accountType}
          {payment.account.external_code}
        </div>
        <div className="account-description">
          {payment.account.description}
        </div>
      </div>
    );
  }

  confirmationCode(payment) {
    if (payment.is_recurring ? payment.is_completed : true) {
      return (
        <div className="confirmation-code col-md-7">
          {this.props.i18n.portal.dashboard.confirmation}
          {` #${ payment.confirmation_code }`}
        </div>
      );
    }
  }

  paymentDate(payment) {
    return (
      <div className="transaction-date col-md-5">
        {format.date(payment.payment_date)}
      </div>
    );
  }

  emptyList() {
    return (
      <section className="no-results text-muted">
        <h1 className="sr-only">{this.props.i18n.portal.ada_headers.no_results}</h1>
        {this.props.i18n.portal.dashboard.no_results_message}
      </section>
    );
  }

  pagination() {
    return (
      <Pagination
        totalItems={this.props.paymentsData.total_payments}
        itemsPerPage={this.props.paymentsData.items_per_page}
        onPageChange={this.props.onPageChange} />
    );
  }

  confirmDeleteModal() {
    return (
      <Modal
        showClose
        showConfirm
        isOpen={this.state.isConfirmDeleteModalOpen}
        toggle={this.toggleConfirmDeleteModal}
        className="confirmation-modal"
        title={this.props.i18n.portal.modals.confirm_delete_header}
        message={this.props.i18n.portal.modals.confirm_delete_message}
        closeLabel={this.props.i18n.common.cancel}
        confirmLabel={this.props.i18n.common.delete}
        confirmClassName="danger"
        onConfirm={this.deleteSelectedPayment}
      />
    );
  }

  toggleConfirmDeleteModal() {
    this.setState({
      isConfirmDeleteModalOpen: !this.state.isConfirmDeleteModalOpen
    });
  }

  deleteSelectedPayment() {
    if (this.state.selectedPayment) {
      this.props.deletePayment(this.state.selectedPayment);
      this.toggleConfirmDeleteModal();
    }
  }

  editPaymentConfirmModal() {
    return (
      <Modal
        showClose
        showConfirm
        isOpen={this.state.isEditPaymentModalOpen}
        toggle={this.toggleEditPaymentModal}
        className="confirmation-modal"
        title={this.props.i18n.portal.modals.confirm_edit_payment_header}
        message={this.props.i18n.portal.modals.confirm_edit_payment_message}
        closeLabel={this.props.i18n.common.cancel}
        confirmLabel={this.props.i18n.common.continue}
        confirmClassName="warning"
        onConfirm={this.editSelectedPayment} />
    );
  }

  editSelectedPayment() {
    this.toggleEditPaymentModal();
    this.props.onEditPayment(this.state.selectedPayment);
  }

  toggleEditPaymentModal() {
    this.setState({isEditPaymentModalOpen: !this.state.isEditPaymentModalOpen});
  }

  render() {
    if (this.props.loading || !this.props.paymentsData) {
      return <Spinner centered size="medium" />;
    }
    else if (this.props.paymentsData.total_payments == 0) {
      return this.emptyList();
    }
    else {
      return (
        <div>
          {this.paymentsList(this.props.paymentsData.payments)}
          {this.pagination()}
          {this.paymentDetailsModal()}
          {this.confirmDeleteModal()}
          {this.editPaymentConfirmModal()}
        </div>
      );
    }
  }
}

PaymentsList.propTypes = {
  paymentsData: PropTypes.object,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func,
  deletePayment: PropTypes.func.isRequired,
  abilities: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  onEditPayment: PropTypes.func.isRequired,
  fetchPayment: PropTypes.func.isRequired,
  fetchedPayment: PropTypes.object,
  fetchingPayment: PropTypes.bool
};

export default PaymentsList;
